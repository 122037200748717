import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import DateInput from '../../components/form/DateInput';
import { Jumbotron, Button } from 'reactstrap';
import { Images } from "../../util/Constant.js";
import DatePicker from "react-datepicker";

export const defaultProps = {
    options: false,
    startDateLabel: "FROM",
    endDateLabel: "TO"
}

const DateRangeFilter = (p) => {

    const props = {
        options: false,
        startDateLabel: "FROM",
        endDateLabel: "TO",
        ...p
    }

    const { t } = useTranslation();

    const [sDate, setSDate] = useState(new Date());
    const [eDate, setEDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState({});
    const [openCalendar, setOpenCalendar] = useState(false);

    const _PREV_DAY = "PREV";
    const _NEXT_DAY = "NEXT";

    const _DATE_SELECTIONS = [
        {
            label: t("PREV_DAY"),
            from: _PREV_DAY,
        },
        {
            label: t("TODAY"),
            from: moment(),
        },
        {
            label: t("YESTERDAY"),
            from: moment().subtract(1, 'day'),
            to: moment().subtract(1, 'day')
        },
        {
            label: t("THIS_WEEK"),
            from: moment().startOf('isoWeek'),
        },
        {
            label: t("LAST_WEEK"),
            from: moment().subtract(1, 'week').startOf('isoWeek'),
            to: moment().subtract(1, 'week').endOf('isoWeek')
        },
        {
            label: t("THIS_MONTH"),
            from: moment().startOf('month'),
        },
        {
            label: t("LAST_MONTH"),
            from: moment().startOf('month').subtract(1, 'month'),
            to: moment().startOf('month').subtract(1, 'month').endOf('month')
        },
        {
            label: t("NEXT_DAY"),
            from: _NEXT_DAY
        },
    ];

    useEffect(() => {
        if (props.startDate) {
            setSDate(props.startDate);
        }

        if (props.endDate) {
            setEDate(props.endDate);
        }
    }, [props]);

    useEffect(() => {
        let startDateMoment;
        let endDateMoment;

        if (selectedOption.from == _PREV_DAY) {
            startDateMoment = moment(props.startDate).subtract(1, 'day');
            endDateMoment = moment(props.startDate).subtract(1, 'day');
        } else if (selectedOption.from == _NEXT_DAY) {
            startDateMoment = moment(props.startDate).add(1, 'day');
            endDateMoment = moment(props.startDate).add(1, 'day');
        } else {
            startDateMoment = selectedOption.from;
            endDateMoment = selectedOption.to ? selectedOption.to : moment();
        }

        setSDate(startDateMoment);
        setEDate(endDateMoment);

    }, [selectedOption]);

    function handleStartDate(date) {
        setSDate(date);
        setOpenCalendar(true);

        if (moment(date).isAfter(eDate)) {
            handleEndDate(date);
        }

        if (props.onStartDateChange) {
            props.onStartDateChange(date);
        }
    }

    function handleEndDate(date) {
        setEDate(date);

        if (moment(sDate).isAfter(date)) {
            handleStartDate(date);
        }

        if (props.onEndDateChange) {
            props.onEndDateChange(date);
        }

        setOpenCalendar(false);
    }

    function handleSave() {
        if (props.onSave) {
            props.onSave(sDate, eDate);
        }
    }

    return (
        <>
            <Jumbotron className="gold-jumbotron d-flex datefilter-wrapper" style={{ marginBottom: "0.3rem" }}>
                <div>
                    <img src={Images._CLOCK_ICON} />
                    <div className="date-wrapper">
                        <span className="m-0">{t(props.startDateLabel)}</span>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            onBlur={() => { setOpenCalendar(false) }}
                            onClickOutside={() => { setOpenCalendar(false) }}
                            selected={moment(sDate).toDate()}
                            onChange={handleStartDate}
                            placeholderText="dd/mm/yyyy"
                            popperPlacement="bottom-start"
                            customInput={<DateInput />}
                        />
                    </div>
                    <div className="date-wrapper">
                        <span className="m-0">{t(props.endDateLabel)}</span>
                        <DatePicker
                            open={openCalendar}
                            onFocus={() => { setOpenCalendar(true) }}
                            onInputClick={() => { setOpenCalendar(true) }}
                            onBlur={() => { setOpenCalendar(false) }}
                            onClickOutside={() => { setOpenCalendar(false) }}
                            dateFormat="dd/MM/yyyy"
                            selected={moment(eDate).toDate()}
                            onChange={handleEndDate}
                            placeholderText="dd/mm/yyyy"
                            popperPlacement="bottom-center"
                            customInput={<DateInput />}
                        />
                    </div>
                    <Button className="theme-btn-2 pull-right" onClick={handleSave}>{t('APPLY')}</Button>
                </div>
            </Jumbotron>
            {
                props.options &&
                <div className="date-selection-wrapper">
                    {
                        _DATE_SELECTIONS.map((selection) => {
                            return <div
                                onClick={() => { setSelectedOption(selection) }}
                                className={classNames("selection-item", { active: selectedOption.label == selection.label })}
                            >
                                {selection.label}
                            </div>
                        })
                    }
                </div>
            }
        </>
    );
};

export default DateRangeFilter;