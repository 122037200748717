import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Language, SessionKey } from "../util/Constant";

import common_en_gb from "./en-GB/common.json";
import common_zh_cn from "./zh-CN/common.json";
import common_zh_hk from "./zh-HK/common.json";
import common_ms_my from "./ms-MY/common.json";
import common_bn_bd from "./bn-BD/common.json";

/// <summary>
/// Author: -
/// </summary>
const _resources = {};
_resources[Language._ENGLISH_GB] = { common: common_en_gb };
_resources[Language._CHINESE_CN] = { common: common_zh_cn };
_resources[Language._CHINESE_HK] = { common: common_zh_hk };
_resources[Language._MALAYSIA_MALAY] = { common: common_ms_my };
_resources[Language._BN_BD] = { common: common_bn_bd };

i18n
    .use(initReactI18next)
    .init({
        defaultNS: "common",
        debug: false,
        resources: _resources,
        lng: localStorage.getItem(SessionKey._LANGUAGE) || Language._ENGLISH_GB,
        fallbackLng: [Language._ENGLISH_GB, Language._CHINESE_CN, Language._CHINESE_HK],
        keySeparator: false,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });


export default i18n;