import React from 'react';
import { Redirect } from 'react-router';
import { WebUrl } from './../util/Constant';

import Home from '../pages/home/Home';
import BettingHistory from '../pages/betting/BettingHistory';
import BettingHistoryDetails from '../pages/betting/BettingHistoryDetails';
import BettingSummary from '../pages/betting/BettingSummary';
import AdvanceBetting from '../pages/betting/AdvanceBetting';
import NumberMeaning from '../pages/num-meaning/NumberMeaning';
import ResultView from '../pages/home/result/ResultView';
import StrikeHistory from '../pages/strike/StrikeHistory';
import BetSuccessful from '../pages/betting/BetSuccessful';
import CancelSuccessful from '../pages/betting/CancelSuccessful';
import PackagesList from '../pages/package/PackagesList';
import BettingPage from '../pages/betting/BettingPage';
import WalletStatement from '../pages/home/WalletStatement';
import BetReport from '../pages/home/BetReport';
import Help from '../pages/home/Help';
import JackpotHistory from '../pages/home/JackpotHistory';
import PcBetWrapper from '../pages/pc/PcbetWrapper';

const routes = [
    {
        path: '/',
        exact: true,
        authenticated: false,
        title: 'Home',
        component: () => <Home />
    },
    {
        path: WebUrl._PLAYGAME,
        exact: true,
        authenticated: false,
        title: 'Play Game',
        component: () => <Home />
    },
    {
        path: WebUrl._BETTING_HISTORY,
        exact: true,
        title: 'Betting History',
        authenticated: true,
        component: () => <BettingHistory />
    },
    {
        path: WebUrl._BETTING_HISTORY_DETAILS,
        exact: true,
        authenticated: true,
        title: 'Betting History Details',
        component: () => <BettingHistoryDetails />
    },
    {
        path: WebUrl._BETTING_PAGE,
        exact: true,
        authenticated: true,
        title: 'Betting',
        component: () => <BettingPage />
    },
    {
        path: WebUrl._BETTING_SUMMARY,
        exact: true,
        authenticated: true,
        title: 'Betting Summary',
        component: () => <BettingSummary />
    },
    {
        path: WebUrl._BETTING_ADVANCE,
        exact: true,
        authenticated: true,
        title: 'Advance Betting',
        component: () => <AdvanceBetting />
    },
    {
        path: WebUrl._NUMBER_MEANING,
        exact: true,
        authenticated: true,
        title: 'Number Meaning',
        component: () => <NumberMeaning />
    },
    {
        path: WebUrl._RESULT_VIEW,
        exact: true,
        authenticated: true,
        title: 'Result',
        component: () => <ResultView />
    },
    {
        path: WebUrl._STRIKE_HISTORY,
        exact: true,
        authenticated: true,
        title: 'Strike History',
        component: () => <StrikeHistory />
    },
    {
        path: WebUrl._BET_SUCCESSFUL,
        exact: true,
        authenticated: true,
        title: 'Successfully Place Bet',
        component: () => <BetSuccessful />
    },
    {
        path: WebUrl._CANCEL_SUCCESSFUL,
        exact: true,
        authenticated: true,
        title: 'Successfully Cancel Bet',
        component: () => <CancelSuccessful />
    },
    {
        path: WebUrl._PACKAGES_LIST,
        exact: true,
        authenticated: true,
        title: 'Packages List',
        component: () => <PackagesList />
    },
    {
        path: WebUrl._WALLET_STATEMENT,
        exact: true,
        authenticated: true,
        title: 'Wallet Statement',
        component: () => <WalletStatement />
    },
    {
        path: WebUrl._BET_REPORT,
        exact: true,
        authenticated: true,
        title: 'Bet Report',
        component: () => <BetReport />
    },
    {
        path: WebUrl._HELP,
        exact: true,
        authenticated: true,
        title: 'Help',
        component: () => <Help />
    },
    {
        path: WebUrl._JACKPOT_HISTORY,
        exact: true,
        authenticated: true,
        title: 'Jackpot History',
        component: () => <JackpotHistory />
    },
    {
        path: WebUrl._BETTING_PC_WRAPPER,
        exact: true,
        authenticated: true,
        title: 'PC Betting',
        component: () => <PcBetWrapper />
    },
];

export default routes;