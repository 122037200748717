import React, { useState, useEffect } from 'react';
import PageNav from '../../components/header/page-navbar.jsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { ApiUrl, ApiKey, WebUrl, Images } from "../../util/Constant.js";
import { formatNumber, initSharer, stringIsNullOrEmpty } from "../../util/Utility.js";
import { setBusy, setIdle, showJsonResponseAlert } from '../../redux/AppAction.js';
import moment from 'moment';
import DatePicker from "react-datepicker";
import DateInput from '../../components/form/DateInput.js';
import { Jumbotron, Button, Input } from 'reactstrap';
import ApiEngine from '../../util/ApiEngine';

const BetReport = () => {
    var { t } = useTranslation();
    var _history = useHistory();
    var _dispatch = useDispatch();

    const [drawDate, setDrawDate] = useState([]);
    const [betReports, setBetReports] = useState([]);
    const [totalEat, setTotalEat] = useState([]);
    const [ticketId, setTicketId] = useState();
    const tagNextLine = "\n";
    const tagSpace = "\\s";
    const _STOCK_WORD = "Stock";

    /// Author:: Wong
    useEffect(() => {
        getBetReport(true);
    }, []);

    /// Author: -
    async function getBetReport(forceSearch = false) {
        _dispatch(setBusy());

        let url = `${ApiUrl._API_GET_BET_REPORT}?DrawDate=${moment(drawDate).format('YYYYMMDD')}`;

        if (_history.location.state) {
            if (_history.location.state.ticketXId && forceSearch) {
                url += `&TicketId=${_history.location.state.ticketXId}&ForceSearch=true`
            }
        }

        if (!forceSearch && !stringIsNullOrEmpty(ticketId)) {
            url += `&TicketId=${ticketId}`;
        }

        await ApiEngine.get(url)
            .then((responseJson) => {
                _dispatch(setIdle());
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let data = responseJson[ApiKey._API_DATA_KEY];

                    data.map((br) => {
                        let shareStr = `Inv. ${br.ticketXId}${tagNextLine}${br.ticketDetails[0].createdAt}${tagNextLine}${tagNextLine}` + br.ticketDetails.map((td) => {
                            return td.number + tagNextLine + td.details.map((det) => {
                                return `${det.poolShortCode}${tagSpace}` + det.eatTypes.map((et) => { return `${et.type.replace(_STOCK_WORD, "")}-${formatNumber(et.amount, 2)}` }).join(tagSpace);
                            })
                        }).join(tagNextLine);

                        // replace next line and spacing to unicode
                        br.shareStr = shareStr.replace(/\\n/gi, '%0a').replace(/\\s/g, ' ').trim();
                    });

                    setBetReports(data);
                    calculateTotalEat(responseJson[ApiKey._API_DATA_KEY]);
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            })
    }

    /// Author: -
    const calculateTotalEat = async (report) => {
        let tempTotalEat = {};

        if (report) {
            report.map((r) => {
                r.ticketDetails.map((d) => {
                    d.details.map((d) => {
                        d.eatTypes.map((et) => {
                            if (!tempTotalEat.hasOwnProperty(et.type)) {
                                tempTotalEat[et.type] = 0;
                            }
                            tempTotalEat[et.type] += et.amount;
                        })
                    })
                })
            })
        }

        setTotalEat(tempTotalEat);
    }

    return (
        <>
            <PageNav
                pageTitle={t('BET_REPORT')}
                pageBackHandler={() => { _history.push(WebUrl._HOME) }} />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div className="set-page-content">
                <div className="page-container container">
                    <Jumbotron className="gold-jumbotron d-flex datefilter-wrapper" style={{ marginBottom: ".7rem" }}>
                        <div>
                            <img src={Images._CLOCK_ICON} />
                            <div className="date-wrapper">
                                <span className="m-0">{t("DRAW_DATE")}</span>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={moment(drawDate).toDate()}
                                    onChange={setDrawDate}
                                    placeholderText="dd/mm/yyyy"
                                    popperPlacement="bottom-start"
                                    customInput={<DateInput />}
                                />
                            </div>
                            <div className="date-wrapper">
                                <span className="m-0">{t("TICKET_ID")}</span>
                                <Input className="form-control form-control-transparent" onChange={(e) => { setTicketId(e.target.value) }} id="txtTicketXId" placeholder={t('ENTER_TICKET_ID')} />
                            </div>
                        </div>
                        <Button className="theme-btn-2 pull-right" onClick={() => getBetReport(false)}>{t('APPLY')}</Button>
                    </Jumbotron>
                    {
                        betReports.length == 0 &&
                        <div className="empty-state-text">{t("NO_RESULT_FOUND")}</div>
                    }
                    <div className="mb-2">
                        {
                            Object.keys(totalEat).map((label) => {
                                return <span className="badge badge-brand badge-total-eat">
                                    <strong>{label.replace(_STOCK_WORD, "")}</strong>: {formatNumber(totalEat[label], 2)}
                                </span>
                            })
                        }
                    </div>
                    <div className="bet-report-list">
                        {
                            betReports.map((br) => {
                                return <div className="bet-report-item" key={br.TicketXId}>
                                    <div className="d-flex align-items-center"><strong>Inv. {br.ticketXId}</strong> <Button className="btn btn-xs f-s-11 ml-1 theme-btn-1" onClick={() => initSharer(br.shareStr)}>{t('SHARE')}</Button></div>
                                    <div style={{ marginBottom: '.5rem' }}>{br.ticketDetails[0].createdAt}</div>
                                    {
                                        br.ticketDetails.map((report) => {
                                            return <div className="bet-ticket-detail" key={`${br.ticketXId}-${report.number}`}>
                                                <div className="bet-report-number">{report.number}</div>
                                                <div>
                                                    <table className="table table-bet-report-item" style={{ color: '#fff' }}>
                                                        {
                                                            report.details.map((det) => {
                                                                return <tr key={det.poolShortCode}>
                                                                    <td width="1%">{det.poolShortCode}</td>
                                                                    <td>
                                                                        {det.eatTypes.map((et) => { return `${et.type.replace(_STOCK_WORD, "")}-${formatNumber(et.amount, 2)}` }).map(et => <span className="bet-detail">{et}</span>)}
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                    </table>
                                                </div>
                                            </div>
                                        })
                                    }
                                    
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default BetReport;