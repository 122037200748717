import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { Images, WebUrl } from '../../util/Constant';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: -
/// </summary>
const CancelSuccessful = props => {
    const _history = useHistory();

    const [lastDateRange, setLastDateRange] = useState();
    var { t } = useTranslation();

    useEffect(() => {
        if (_history.location.state) {
            console.log(_history.location.state.lastDateRange);
            setLastDateRange(_history.location.state.lastDateRange);
        }
    }, [_history]);

    return (
        <>
            <div id="betSuccessful" style={{ minHeight: "100vh" }}>
                <div className="page-container container">
                    <div className="page-layout-set-vertical">
                        <div className="page-layout-set-horizontal text-center">
                            <div className="success-img-wrapper m-b-40">
                                <div className="success-icon-block"><img src={Images._SUCCESS_BET_ICON}></img></div>
                            </div>
                            <h3>{t("CANCEL_SUCCESSFUL")}</h3>
                            <div className="w-50 m-auto success-description">
                                <p className="theme-text-3 f-s-17 sfp-medium">{t("CANCEL_SUCCESSFUL_WORDS")}
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button onClick={() => _history.push(WebUrl._BETTING_HISTORY, { lastDateRange })} className="btn btn-lg theme-btn-1">{t("OKAY")}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CancelSuccessful;
