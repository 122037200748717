import React, { useEffect, useState } from 'react';
import PageNav from '../../components/header/page-navbar.jsx';
import { Row, Col } from 'reactstrap';
import { ApiUrl, ApiKey, Images, WebUrl } from "../../util/Constant.js";
import moment from 'moment';
import { stringIsNullOrEmpty, MapBrandsLogoImages } from '../../util/Utility.js';
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import DateRangeFilter from '../../components/date-picker/DateRangeFilter';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';

const StrikeHistory = props => {
    var { t } = useTranslation();
    const _dispatch = useDispatch();

    const [sDate, setSDate] = useState(new Date());
    const [eDate, setEDate] = useState(new Date());
    const [strikeRecords, setStrikeRecords] = useState([]);

    useEffect(() => {
        getStrikeReport();
    }, []);

    async function getStrikeReport() {
        let url = ApiUrl._API_GET_STRIKE_REPORT;
        url += `?date_from=${moment(sDate).format("yyyyMMDD")}` + `&date_to=${moment(eDate).format("yyyyMMDD")}`;

        await ApiEngine.get(url).then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setStrikeRecords(responseJson[ApiKey._API_DATA_KEY]);
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            });
    }

    return (
        <>
            <PageNav pageTitle={t("STRIKE_HISTORY")} showDate={false} />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div id="strikeHistory" className="set-page-content">
                <div className="page-container container">
                    <div className="page-layout-set-vertical">
                        <div className="page-layout-set-horizontal">
                            <DateRangeFilter
                                startDate={sDate}
                                endDate={eDate}
                                startDateLabel={t("FROM_DRAWDATE")}
                                endDateLabel={t("TO_DRAWDATE")}
                                onStartDateChange={setSDate}
                                onEndDateChange={setEDate}
                                onSave={getStrikeReport}
                            />
                            <div className="recent-history-container m-b-10">
                                {strikeRecords.map((item) => (
                                    <div className="history-item p-10" key={item.id}>
                                        <Row>
                                            <Col xs="6">
                                                <div className="d-flex">
                                                    {(() => {
                                                        var { logo, classname } = MapBrandsLogoImages(item.poolId)
                                                        return (< div > <img src={logo} className={classname}></img></div>)
                                                    })()}
                                                    <h3 className="p-l-10 f-s-22 text-white">{item.betNumber}</h3>
                                                </div>
                                                <div className="theme-text-4 sfp-regular">{t('ORDER_ID')}: {item.betId}</div>
                                                <div className="theme-text-4 sfp-regular">{t('DRAW_DATE')}: {item.drawDate}</div>
                                                <div className="theme-text-4 sfp-regular">{t('PRIZE_NAME')}: {item.prizeName}</div>
                                                <div className="theme-text-4 sfp-regular">{t('BET_AMOUNT')}: {item.betType} - {item.stock}</div>
                                                {item.remark && <div className="theme-text-4 sfp-regular">{t('REMARK')}: {item.remark}</div>}
                                            </Col>
                                            <Col xs="6" className="d-flex">
                                                <div className="m-l-auto m-t-auto m-b-auto"><h3>{item.totalWin}</h3></div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                            <div className="d-flex justify-content-center w-100 m-t-20">
                                <small className="theme-text-3 f-s-15">{t('NO_MORE_RECORDS')}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StrikeHistory;