import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DropdownLanguage from './dropdown/language.jsx';
import moment from 'moment';
import { PageSettings } from './../../config/page-settings.js';
import { withRouter } from 'react-router-dom';
import Confirmation from '../content/Confirmation';
import { AppConfiguration } from "read-appsettings-json";
import { APP_TYPE } from '../../util/Constant';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.toggleMegaMenu = this.toggleMegaMenu.bind(this);
        this.state = { collapseMegaMenu: false };
        this.date = moment().format('ddd, ll');
        this.appType = AppConfiguration.Setting().ApplicationConfig.appType;

        this.modalOpen = false;
    }

    toggleMegaMenu() {
        this.setState({ collapseMegaMenu: !this.state.collapseMegaMenu });
    }

    componentDidMount() {
    }

    render() {
        return (
            <PageSettings.Consumer>
                {({ toggleMobileSidebar, toggleRightSidebar, toggleMobileRightSidebar, toggleMobileTopMenu, pageHeaderLanguageBar, pageHeaderMegaMenu, pageTwoSidebar, pageTopMenu, pageSidebar }) => (
                    <div id="header" className="header navbar-default">
                        <div className="navbar-header w-100">
                            <div className="navbar-brand">
                                <Link to="/" className="d-flex">
                                    <div className="logo-wrapper">
                                        <div className="navbar-logo">
                                            <img src={require(`../../assets/img/logo/logo-${this.appType}.png`)} style={{ marginRight: 5 }} />
                                        </div>
                                    </div>
                                    <div className="logo-text-wrapper">
                                        <span className="logo-text">{this.appType == APP_TYPE._NORMAL ? "JJLotto" : "GD"}</span>
                                        <span className="date-text">{this.date}</span>
                                    </div>
                                </Link>
                            </div>

                            {pageHeaderLanguageBar && (
                                <>
                                    <button className="btn theme-btn-1 btn-exit" onClick={() => { this.setState({ modalOpen: true }) }}>
                                        <i className="las la-sign-out-alt"></i>
                                    </button>
                                    <DropdownLanguage />
                                    <Confirmation isModalOpen={this.state.modalOpen} setModalOpen={(toggle) => { this.setState({ modalOpen: toggle }) }} />
                                </>
                            )}
                        </div>
                    </div>
                )}
            </PageSettings.Consumer>
        )
    }
}

export default withRouter(Header);
