import React, { useEffect, useState } from 'react';
import { stringIsNullOrEmpty, numberWithCurrencyFormat } from '../../util/Util';
import { EatType, OrderMethod } from "../../util/Constant";
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';

/// <summary>   
/// Author: -
/// </summary>
const WebOrderOnePanel = props => {
    const { t } = useTranslation();
    const { control } = useForm();
    const { option, poolSelection, maxLine, betTypeOption, totalDay, onDataChanged, numberLength, onBetSubmit, isAutoTicket, setAutoTicket, setRedBet } = props;
    const [orderData, setOrderData] = useState([]);
    const [dayCount, setDayCount] = useState(0);
    var permutations = require('permutation');
    const [currentOption, setCurrentOption] = useState('');

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (totalDay != dayCount) {
            setDayCount(totalDay);
            calculateTotalAmount(orderData);
        }
        else if (option[0].name != currentOption) {
            setOrderData([]);
            setCurrentOption(option[0].name);
            cleanUpMassUpdateInputs();
        }
    }, [option, totalDay]);

    /// <summary>
    /// Author: -
    /// </summary>
    function updateData(field, id, value) {
        let newOrderData = [...orderData];
        let orderIndex = newOrderData.findIndex(x => x.id === id);
        if (orderIndex > -1) {
            newOrderData[orderIndex][field] = value;
        }
        else {
            newOrderData.push({ id: id, [field]: value });
        }
        calculateTotalAmount(newOrderData);
    }

    /// <summary>
    /// Author: -
    /// </summary>
    function updateMassData(field, value) {
        let newOrderData = [...orderData];
        for (var i = 0; i < maxLine; i++) {
            let orderIndex = newOrderData.findIndex(x => x.id === i)
            if (orderIndex > -1) {
                newOrderData[orderIndex][field] = value;
            }
            else {
                newOrderData.push({ id: i, [field]: value });
            }
        }
        calculateTotalAmount(newOrderData);
    }

    /// <summary>
    /// Author: -
    /// </summary>
    function calculateTotalAmount(newOrderData) {
        let newOption = [...option];
        newOrderData = newOrderData.filter(n => !stringIsNullOrEmpty(n.number));
        for (let i = 0; i < newOrderData.length; i++) {
            let sum = 0;
            let permutation = getPermutation(newOrderData[i]);
            let totalPool = Object.values(newOrderData[i]).reduce((a, pool) => a + (pool === true ? 1 : 0), 0);

            for (var key in newOrderData[i]) {
                if (option.find(o => o.key == key)) {
                    let amount = !stringIsNullOrEmpty(newOrderData[i][key]) ? parseFloat(newOrderData[i][key]) : 0;
                    sum += amount;
                    newOption[option.findIndex(o => o.key == key)].total += amount * totalDay * totalPool * permutation;
                }
            }
            newOrderData[i].rowTotal = sum * totalDay * totalPool * permutation;
        }
        setOrderData(newOrderData);
        onDataChanged(newOption);
    }

    /// <summary>
    /// Author: -
    /// </summary>
    function getPermutation(newOrderData) {
        let permutation = 1;

        // BOX  
        if (newOrderData.betType == betTypeOption[1].value) {
            permutation = permutations(newOrderData.number, { unique: true }).length;
        }
        // PH
        else if (newOrderData.betType == betTypeOption[3].value) {
            permutation = permutations(newOrderData.number.substring(1), { unique: true }).length;
        }
        // PB
        else if (newOrderData.betType == betTypeOption[4].value) {
            permutation = permutations(newOrderData.number.slice(0, -1), { unique: true }).length;
        }

        return permutation;
    }

    /// <summary>
    /// Author: -
    /// Origin: -
    /// </summary>
    function cleanUpMassUpdateInputs() {
        for (let i = 0, massUpdateInputs = document.querySelectorAll('.input-mass-update'); i < massUpdateInputs['length']; i++) {
            massUpdateInputs.item(i).value = '';
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function submit(data, type) {
        let isSuccess = await onBetSubmit(data, type);

        if (isSuccess) {
            onDataChanged([...option.map(o => { o.total = 0; return o; })]);
            setOrderData([]);
            cleanUpMassUpdateInputs();
        }
    }

    return (
        <>
            <div className="form-group">
                <div style={{ 'display': 'flex' }}>
                    <button className="btn theme-btn-1" style={{ "marginRight": "5px", "minWidth": "150px" }}
                        onClick={() => {
                            submit(orderData, OrderMethod._MULTIPY);
                        }}>{t('SUBMIT_BET')}</button>
                </div>
            </div>
            {
                option &&
                <div className="table-responsive">
                    <table className="table table-striped table-bordered package-table table-weborder">
                        <thead className="orderPanelHeader">
                            <tr className="orderPanel">
                                <th style={{ 'width': '3%' }}>{t('NO.')}</th>
                                <th>{t('NUMBER')}</th>
                                {
                                    option.map((subType, index) => {
                                        return (
                                            <th key={index}>{t(subType['name'])}</th>
                                        );
                                    })
                                }
                                <th style={{ 'width': '10%' }}>{t('BET_TYPE')}</th>
                                {
                                    poolSelection.filter(p => option[0].key == EatType._5D && p.has5D ||
                                        option[0].key == EatType._6D && p.has6D ||
                                        option[0].key != EatType._5D && option[0].key != EatType._6D).map((pool, index) => {
                                            return (
                                                <th key={index} className={'orderPanel'}>
                                                    <img src={require("../../assets/img/pool-image/" + pool.poolImage)}
                                                        style={{ 'width': '20px', 'height': '20px', 'borderRadius': '20px', 'backgroundColor': 'white' }} />
                                                </th>
                                            );
                                        })
                                }
                                <th>{t('TOTAL')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td />
                                <td />
                                {
                                    option.map((subType, index) => {
                                        return (
                                            <td key={index}>
                                                <input
                                                    className={'input-mass-update form-control package-input'}
                                                    type={'number'}
                                                    min={0}
                                                    onInput={(e) => {
                                                        updateMassData(subType['key'], e.target.value);
                                                    }} />
                                            </td>
                                        );
                                    })
                                }
                                <td />
                                {
                                    poolSelection.filter(p => option[0].key == EatType._5D && p.has5D ||
                                        option[0].key == EatType._6D && p.has6D ||
                                        option[0].key != EatType._5D && option[0].key != EatType._6D).map((pool, index) => {
                                            return (
                                                <td key={index} className={'orderPanel ' + pool.shortCode} style={{ textAlign: 'center' }} >
                                                    <input type="checkbox"
                                                        onChange={(e) => {
                                                            updateMassData(pool.name, e.target.checked);
                                                        }} />
                                                </td>
                                            );
                                        })
                                }
                                <td />
                            </tr>
                            {[...Array(parseInt(maxLine))].map((x, i) =>
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                        <input
                                            style={{ fontSize: '.8rem' }}
                                            className={'input-mass-update form-control package-input'}
                                            type={'number'}
                                            value={orderData.find(o => o.id == i) ? orderData[orderData.findIndex(o => o.id == i)].number : ''}
                                            placeholder={t('NUMBER')}
                                            onInput={(e) => {
                                                (e.target.value.length <= numberLength) && updateData('number', i, e.target.value);
                                            }} />
                                    </td>
                                    {
                                        option.map((subType, index) => {
                                            return (
                                                <td key={index}>
                                                    <input
                                                        disabled={orderData.find(o => o.id == i) && orderData[orderData.findIndex(o => o.id == i)].number &&
                                                            orderData[orderData.findIndex(o => o.id == i)].number.length == subType.maxLength ? false : (subType.allowExceed && orderData[orderData.findIndex(o => o.id == i)]?.number.length > subType.maxLength) ? false : true}
                                                        style={{ fontSize: '.8rem' }}
                                                        className={'input-mass-update form-control package-input'}
                                                        type={'number'}
                                                        value={orderData.find(o => o.id == i) ? orderData[orderData.findIndex(o => o.id == i)][`${subType['key']}`] : ''}
                                                        onInput={(e) => {
                                                            updateData(subType['key'], i, e.target.value);
                                                        }} />
                                                </td>
                                            );
                                        })
                                    }
                                    <td>
                                        <Controller
                                            defaultValue={betTypeOption[0].value}
                                            name="betType"
                                            render={({ onChange, value, name }) => {
                                                return (
                                                    <Select
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), container: (base) => ({ ...base, height: '30px', paddingLeft: '0.1px!important' }), fontsize: '.8rem', height: '30px', paddingLeft: '0.1px'  }}
                                                        isSearchable={false}
                                                        name={name}
                                                        value={orderData.find(o => o.id == i) ? orderData[orderData.findIndex(o => o.id == i)].value : betTypeOption[0]}
                                                        options={betTypeOption}
                                                        onChange={(value) => {
                                                            onChange(value['value']);
                                                            updateData(name, i, value['value']);
                                                        }} />
                                                )
                                            }}
                                            control={control} />
                                    </td>
                                    {
                                        poolSelection.filter(p => option[0].key == EatType._5D && p.has5D ||
                                            option[0].key == EatType._6D && p.has6D ||
                                            option[0].key != EatType._5D && option[0].key != EatType._6D).map((pool, index) => {
                                                return (
                                                    <td key={index} className={'orderPanel ' + pool.shortCode} style={{ textAlign: 'center' }}>
                                                        <input type="checkbox"
                                                            checked={orderData.find(o => o.id == i) ? orderData[orderData.findIndex(o => o.id == i)][`${pool.name}`] : ''}
                                                            onChange={(e) => {
                                                                updateData(pool.name, i, e.target.checked);
                                                            }} />
                                                    </td>
                                                );
                                            })
                                    }
                                    <td>
                                        <strong style={{ 'fontSize': '15px', 'fontWeight': 'bold' }}>
                                            {orderData.find(o => o.id == i) ? numberWithCurrencyFormat(orderData[orderData.findIndex(o => o.id == i)].rowTotal) : ''}
                                        </strong>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
}

export default WebOrderOnePanel;