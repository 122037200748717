import React, { useEffect, useState } from 'react';
import PageNav from '../../components/header/page-navbar.jsx';
import { Row, Col } from 'reactstrap';
import { ApiUrl, ApiKey, Images, WebUrl } from "../../util/Constant.js";
import moment from 'moment';
import { stringIsNullOrEmpty, MapBrandsLogoImages, formatNumber } from '../../util/Utility.js';
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import DateRangeFilter from '../../components/date-picker/DateRangeFilter';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';

/// <summary>
/// Author: -
/// </summary>
const JackpotHistory = props => {
    var { t } = useTranslation();
    const _dispatch = useDispatch();

    const [sDate, setSDate] = useState(new Date());
    const [eDate, setEDate] = useState(new Date());
    const [records, setRecords] = useState([]);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        getJackpotHistory();
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    function getJackpotHistory() {
        let url = ApiUrl._API_GET_JACKPOT_HISTORY;
        url += `?date_from=${moment(sDate).format("yyyyMMDD")}&date_to=${moment(eDate).format("yyyyMMDD")}`;

        ApiEngine.get(url).then((responseJson) => {
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setRecords(responseJson[ApiKey._API_DATA_KEY]);
            }
            else {
                _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            }
        });
    }

    return (
        <>
            <PageNav pageTitle={t("JACKPOT_HISTORY")} showDate={false} />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div id="strikeHistory" className="set-page-content">
                <div className="page-container container">
                    <div className="page-layout-set-vertical">
                        <div className="page-layout-set-horizontal">
                            <DateRangeFilter
                                startDate={sDate}
                                endDate={eDate}
                                startDateLabel={t("FROM_DRAWDATE")}
                                endDateLabel={t("TO_DRAWDATE")}
                                onStartDateChange={setSDate}
                                onEndDateChange={setEDate}
                                onSave={getJackpotHistory}
                            />
                            <div className="recent-history-container m-b-10">
                                {records.map((item) => (
                                    <div className="history-item jackpot-history-item p-10" key={item.id}>
                                        <div className="header-wrapper">
                                            <div className="d-flex align-items-center">
                                                <span className="badge badge-total-eat">{item.jackpotType}</span>
                                                <strong>{item.jackpotNumber}</strong>
                                            </div>
                                            <div className="title-label">{item.drawDateString}</div>
                                        </div>
                                        <div className="prize-wrapper">
                                            <div>
                                                {formatNumber(item.prize)}
                                                <span className="title-label">{t("PRIZE")}</span> 
                                            </div>
                                            <div>
                                                {item.unit}
                                                <span className="title-label">{t("UNIT")}</span>
                                            </div>
                                            <div>
                                                {formatNumber(item.winning)}
                                                <span className="title-label">{t("WIN")}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {
                                records.length == 0 &&
                                <div className="d-flex justify-content-center w-100 m-t-20">
                                    <small className="theme-text-3 f-s-15">{t('NO_MORE_RECORDS')}</small>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JackpotHistory;