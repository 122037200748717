import React, { useState, useEffect } from "react";
import {
    Jumbotron, Row, Col, Button,
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import PageNav from '../../components/header/page-navbar.jsx';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import PostIcon from '../../assets/img/theme/post-it.svg';
import { ApiUrl, ApiKey, Images, WebUrl } from "../../util/Constant.js";
import moment from 'moment';
import { stringIsNullOrEmpty, formatNumber } from '../../util/Utility.js';
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import DateRangeFilter from '../../components/date-picker/DateRangeFilter';
import ApiEngine from '../../util/ApiEngine';

/// <summary>
/// Author: -
/// </summary>
const BettingHistory = props => {
    var { t } = useTranslation();
    const endDateRef = React.createRef();

    const _location = useLocation();
    const _history = useHistory();
    const _dispatch = useDispatch();

    const [sDate, setSDate] = useState();
    const [eDate, setEDate] = useState();

    const [activeTab, setActiveTab] = useState('1');
    const [historyMap, setHistoryMap] = useState([]);

    /// <summary>
    /// Author: -
    /// </summary>
    const toggleTabsHandler = tab => {
        if (activeTab !== tab) setActiveTab(tab);
        getTicketList(tab);
    }

    const onClickNavigateHistoryDetail = (item) => {
        var dataId = item.betId;
        var dataDateTime = item.datetime;
        var total = item.value;
        var status = item.status;

        _history.push({
            pathname: WebUrl._BETTING_HISTORY_DETAILS,
            state: {
                name: dataId, date: dataDateTime, total: total, status: status, lastDateRange: { startDate: sDate, endDate: eDate }, lastActiveTab: activeTab
            }
        })
    }

    useEffect(() => {
        if (_location.state?.lastDateRange != null) {
            let lastDateRange = _location.state.lastDateRange;
            let lastActiveTab = _location.state.lastActiveTab ?? "1";

            setSDate(lastDateRange.startDate);
            setEDate(lastDateRange.endDate);

            setActiveTab(lastActiveTab);
            getTicketList(lastActiveTab, lastDateRange);
        } else {
            getTicketList(1);
        }
    }, []);

    async function getTicketList(status, forceDateRange = null) {
        _dispatch(setBusy());
        let url = ApiUrl._API_GET_TICKET_LIST;

        let filterDateRange = {
            startDate: sDate,
            endDate: eDate
        };

        if (forceDateRange) {
            filterDateRange = forceDateRange;
        }

        if (!stringIsNullOrEmpty(filterDateRange)) {
            url += `?datefrom=${moment(filterDateRange.startDate).format("MM/DD/yyyy")}` + `&dateto=${moment(filterDateRange.endDate).format("MM/DD/yyyy")}` + `&targetstatus=${status}`;
        }

        await ApiEngine.get(url)
            .then((responseJson) => {
                _dispatch(setIdle());
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setHistoryMap(responseJson[ApiKey._API_DATA_KEY]);
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            });
    }

    const renderTickets = () => {
        return <>
            {
                historyMap.length > 0 ?
                    <>
                        {
                            historyMap.map((item) => (
                                <div key={item.betId} className="history-content-item m-b-10" onClick={() => { onClickNavigateHistoryDetail(item) }}>
                                    <div className="p-7 d-flex justify-content-between align-items-center">
                                        <img className="mr-1" src={PostIcon} />
                                        <div className="bet-item-content" style={{ flex: 1 }}>
                                            <div className="text-white m-t-auto">{t("ORDER_ID")}: <span>{item.betId}</span></div>
                                            <div className="theme-text-4"><span>{item.datetime}</span></div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2">-{formatNumber(item.value)}</span>
                                            <i className="las la-angle-right"></i>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                    :
                    <div className="d-flex justify-content-center w-100 m-t-20">
                        <small className="theme-text-3 f-s-15">{t('NO_MORE_RECORDS')}</small>
                    </div>
            }
        </>
    }

    return (
        <>
            <PageNav pageTitle={t('BETTING_HISTORY')} pageBackHandler={() => { _history.push(WebUrl._HOME) }} showDate={false} />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div id="bettingHistory" className="set-page-content">
                <div className="page-container container">
                    <div className="page-layout-set-vertical">
                        <div className="page-layout-set-horizontal">
                            <DateRangeFilter
                                startDate={sDate}
                                endDate={eDate}
                                startDateLabel="FROM_DRAWDATE"
                                endDateLabel="TO_DRAWDATE"
                                onStartDateChange={setSDate}
                                onEndDateChange={setEDate}
                                onSave={(start, end) => { getTicketList(activeTab) }}
                            />
                            <div className="betting-history-content">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggleTabsHandler('1'); }}
                                        >
                                            {t('ACTIVE')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { toggleTabsHandler('2'); }}
                                        >
                                            {t('CANCELLED')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { toggleTabsHandler('3'); }}
                                        >
                                            {t('FAILED')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent className="pl-0 pr-0" activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        {renderTickets()}
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {renderTickets()}
                                    </TabPane>
                                    <TabPane tabId="3">
                                        {renderTickets()}
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default BettingHistory;