import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SessionKey, Language } from "../../../util/Constant.js";

/// <summary>
/// Author: -
/// </summary>
const DropdownLanguage = props => {
    const { i18n } = useTranslation();
    const [languageOptionVisibility, setLanguageOptionVisibility] = useState(false);
    const _LANGUAGE_OPTION = [
        {
            title: 'English',
            prefix: 'EN',
            value: Language._ENGLISH_GB
        },
        {
            title: "简体中文",
            prefix: "简体",
            value: Language._CHINESE_CN
        },
        {
            title: "Melayu",
            prefix: "MY",
            value: Language._MALAYSIA_MALAY
        },
        {
            title: "বাংলাদেশ",
            prefix: "বাং",
            value: Language._BN_BD
        }
    ];

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
         onChangeLanguage((localStorage.getItem(SessionKey._LANGUAGE) ?? Language._ENGLISH_GB));
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    function onChangeLanguage(selectedLanguage) {
         i18n.changeLanguage(selectedLanguage);
         localStorage.setItem(SessionKey._LANGUAGE, selectedLanguage);
    }

    return (
        <Dropdown isOpen={languageOptionVisibility} toggle={() => setLanguageOptionVisibility(!languageOptionVisibility)} className="dropdown navbar-languager" tag="div">
            <DropdownToggle className="dropdown-toggle" tag="a">
                <span className="las la-globe"></span>
                <span className="name d-sm-inline">{_LANGUAGE_OPTION.filter(i => i.value == (localStorage.getItem(SessionKey._LANGUAGE) ?? Language._ENGLISH_GB))[0].prefix}</span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul" right>
                {
                    _LANGUAGE_OPTION.map((language, index) => {
                        return <DropdownItem key={index} onClick={() => { return onChangeLanguage(language.value) }}><span>{language.title}</span></DropdownItem>
                    })
                }
            </DropdownMenu>
        </Dropdown>
    );
};

export default DropdownLanguage;