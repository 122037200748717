import React, { useEffect, useState } from 'react';
import PageNav from '../../components/header/page-navbar.jsx';
import { Button } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import ConfirmationFooter from '../../components/footer/ConfirmationFooter.js';
import { MapBrandsLogoImages, formatNumber } from '../../util/Utility.js';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: -
/// </summary>
const renderBetSummary = (betItem, deleteBtnHandler) => {
    return (betItem.map((item, index) => (
        <div className="bet-item m-b-10 p-10" key={index}>
            <div className="d-flex">
                <div className="d-grid w-50">
                    <div>
                        <span className="f-s-22 text-white sfp-bold">{item.number} <small className="sfp-regular">{item.pattern === "NORMAL"? "": "(" + item.pattern +")"}</small></span>
                        <div className="d-flex">
                            {item.poolList.map((elem, index) => {
                                var { logo, classname } = MapBrandsLogoImages(elem)
                                return (
                                    <div className="icon-wrapper m-r-5" key={index}>
                                        <img src={logo} className={classname} width="50px" />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className="bet-item-trash-btn w-50 text-right">
                    <span className="m-r-10 f-s-18 sfp-bold">{formatNumber(item.totalAmount)}</span>
                    <Button className="fa fa-trash theme-btn-4" onClick={() => deleteBtnHandler(item.id)}></Button>
                </div>
            </div>
            <div className="d-flex flex-row flex-wrap line-height-1 mt-2">
                {item.betType.map((elem, index) => {
                    return (
                        <div className="p-2 m-r-5" key={index}>
                            <p className="theme-text-4 f-s-15 m-0 sfp-regular" style={{ minWidth: '100px' }}>{elem.name + ' - ' + formatNumber(elem.amount)}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )))
}



/// <summary>
/// Author: -
/// </summary>
const BettingSummary = props => {
    const _history = useHistory();
    const _location = useLocation();
    const [betItem, setBetItem] = useState([]);
    const [poolType, setPoolType] = useState('');
    const [drawDateStr, setDrawDateStr] = useState('');
    const [grandTotal, setGrandTotal] = useState(0);
    var { t } = useTranslation();

    function deleteBtnHandler(id) {
        var cartObject = JSON.parse(sessionStorage.getItem("OrderCart"));
        if (cartObject != null) {

            var remainItems = cartObject.betList.filter((item) => item.id !== id);
            setBetItem(remainItems);
            cartObject.betList = remainItems;

            var grandTotalCalculate = 0;
            cartObject.betList.map((bt, idx1) => {
                grandTotalCalculate += bt.totalAmount;
            });
            setGrandTotal(grandTotalCalculate);

            sessionStorage.setItem("OrderCart", JSON.stringify(cartObject));
        }
    }

    const deleteAllHandler = () => {
        var cartObject = JSON.parse(sessionStorage.getItem("OrderCart"));
        if (cartObject != null) {
            setBetItem([]);
            cartObject.betList = [];
            setGrandTotal(0);
            sessionStorage.setItem("OrderCart", JSON.stringify(cartObject));
        }
    }

    useEffect(() => {
        var grandTotalCalculate = 0;

        var cartObject = JSON.parse(sessionStorage.getItem("OrderCart"));
        if (cartObject != null) {
            setPoolType(cartObject.name);
            setBetItem(cartObject.betList);

            cartObject.betList.map((bt, idx1) => {
                grandTotalCalculate += bt.totalAmount;
            });
            
            setGrandTotal(grandTotalCalculate);

            var drawdatetemp = "";

            // split the draw date to shows in comma deliminated
            cartObject.dates.map((item, index) => {
                if (item.active === true) {
                    if (index !== 0) {
                        drawdatetemp += ", ";
                    }
                    drawdatetemp += item.date + " (" + item.id + ")";
                }
            })
            setDrawDateStr(drawdatetemp);
        }
        else {
            setBetItem([]);
        }
    }, [grandTotal])

    return (
        <>
            <PageNav pageTitle={t("YOUR_BET") + ` (${betItem.length})`} showDate={false} showDeleteBtn={true} deleteAllHandler={deleteAllHandler} />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
                <div className="bg-2"></div>
                <div className="bg-3"></div>
            </div>
            <div id="bettingSummary" className="set-page-content">
                <div className="page-container container">
                    <div className="page-layout-set-vertical">
                        <div className="page-layout-set-horizontal">
                            <p className="theme-text-3 f-s-15 sfp-medium">
                                <span className="text-white">{t("DRAW_DATES")}: </span>
                                {drawDateStr}
                                </p>
                            <div className="d-flex justify-content-center">
                                <Button style={{ 'padding': '10px 20px' }} onClick={() => _history.goBack()} className="theme-btn-1 sfp-bold f-s-15"><i className="fa fa-plus" /> {t("ADD_MORE_BET")}</Button>
                            </div>
                            <div className="bet-item-wrapper">
                                {(() => {
                                    return renderBetSummary(betItem, deleteBtnHandler)
                                })()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationFooter totalAmount={" " + formatNumber(grandTotal) } />
        </>
    )
};

export default BettingSummary;
