import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
    Navbar, Nav, NavItem, Button
} from 'reactstrap';
import moment from 'moment';
import classnames from 'classnames';
import NoteIcon from '../../assets/img/theme/note.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/// <summary>
/// Author: -
/// </summary>
const PageNav = props => {
    const {
        showInfoBtn,
        showDate,
        history,
        pageTitle,
        logoSrc,
        logoStyle,
        showDeleteBtn,
        showRefreshBtn,
        pageBackHandler,
        deleteAllHandler,
        infoBtnHandler,
        number,
        headerOption,
        onRefresh = null,
        ...rest
    } = props;

    const [date, setDate] = useState(moment().format('ddd, ll'));

    /// <summary>
    /// Author: -
    /// </summary>
    const navigationBtnOnClickHandler = () => {
        if (typeof pageBackHandler === 'function') {
            pageBackHandler()
        }
        else {
            history.goBack()
        }
    }

    const deleteBtnOnClickHandler = () => {
        if (typeof deleteAllHandler === 'function') {
            deleteAllHandler()
        }
    }

    const infoBtnOnClickHandler = () => {
        if (typeof infoBtnHandler === 'function') {
            infoBtnHandler()
        }
    }

    var { t } = useTranslation();

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
    }, []);

    return (
        <Navbar id="page-navigator">
            <Nav className="mr-auto" navbar>
                <NavItem className="d-flex">
                    <Button className="page-back-btn" onClick={navigationBtnOnClickHandler}>
                        <i className="fa fa-angle-left"></i>
                    </Button>
                    {logoSrc && (
                        <div className="m-auto">
                            <img src={logoSrc} style={logoStyle ? logoStyle : null} />
                        </div>)}
                    <div className="logo-text-wrapper m-auto unselectable">
                        <span className="logo-text">{pageTitle}</span>
                        {showDate && (<span className="date-text">{date}</span>)}
                    </div>
                </NavItem>
            </Nav>
            { showInfoBtn && (
                <Button className="page-nav-info-btn" onClick={infoBtnOnClickHandler}>
                    <img src={NoteIcon} />
                    {
                        number > 0 &&
                        <span className="label">{number}</span>
                    }
                </Button>
            )}
            {
                showRefreshBtn && (
                    <Button onClick={onRefresh} className="page-nav-refresh-btn btn btn-lg">
                        <i className="fa fa-repeat"></i>
                    </Button>
                )
            }
            { showDeleteBtn && (
                <Button className="cancel-btn btn-lg" onClick={deleteBtnOnClickHandler}>
                    {t("DELETE_ALL")}
                </Button>
            )}
            {
                headerOption
            }
        </Navbar>
    )
}

PageNav.defaultProps = {
    logoStyle: null,
    logoSrc: null,
    showDate: false,
    pageTitle: PropTypes.string,
    showInfoBtn: false,
    showDeleteBtn: false,
    headerOption: ''
}

export default withRouter(PageNav);