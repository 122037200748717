import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from 'react-router-dom';
import PageNav from '../../components/header/page-navbar.jsx';
import { Row, Col, Jumbotron, Button, Modal, ModalBody } from 'reactstrap';
import { stringIsNullOrEmpty, formatNumber, initSharer } from "../../util/Utility.js";
import CancelStateModel from "./CancelStateModel.js";
import { ApiUrl, ApiKey, Images, WebUrl, SHARE_PLATFORM } from "../../util/Constant.js";
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ApiEngine from '../../util/ApiEngine';

const defaultvalue =
{
    orderstring: '',
    orderresponse: ''
}

/// <summary>
/// Author: -
/// </summary>
const BettingHistoryDetails = props => {
    var { t } = useTranslation();

    const _history = useHistory();
    const _location = useLocation();
    const _dispatch = useDispatch();
    const [betId, setBetId] = useState('')
    const [date, setDate] = useState('');
    const [isModalOpen, setModalOpen] = useState(false);
    const [ticketDetails, setTicketDetails] = useState(defaultvalue);
    const [total, setTotal] = useState(0.00);
    const [lastDateRange, setLastDateRange] = useState();
    const [lastActiveTab, setLastActiveTab] = useState();
    const [shareModal, setShareModal] = useState(false);
    const [rebetModal, setRebetModal] = useState(false);

    const _userData = useSelector(state => state.authState.userData);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (!_location.state) {
            _history.push(WebUrl._BETTING_HISTORY);
            return;
        }

        if (!stringIsNullOrEmpty(_location.state.name)) {
            setBetId(_location.state.name);
            setDate(_location.state.date);
            setTotal(parseFloat(_location.state.total));
            getTicketDetails(_location.state.name, _location.state.date);
            setLastDateRange(_location.state.lastDateRange);
            setLastActiveTab(_location.state.lastActiveTab);
        }
    }, [_location])

    const toggleModal = () => setModalOpen(!isModalOpen);

    function getTicketDetails(betId, date) {
        let url = ApiUrl._API_GET_TICKET_DETAILS;

        url += `?betId=${betId}` + `&dateTime=${date}`;
        ApiEngine.get(url)
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setTicketDetails(responseJson[ApiKey._API_DATA_KEY]);
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            });
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const getShareContent = (baseUrl) => {
        let urlEncodedText = ticketDetails.orderresponse.replace(/\\n/gi, '%0a').replace(/&/g, '');
        return urlEncodedText;
    }

    return (
        <>
            <PageNav
                pageTitle={t('ORDER_ID') + ` : ${betId}`}
                showDate={false}
                pageBackHandler={() => {
                    _history.push({
                        pathname: WebUrl._BETTING_HISTORY,
                        state: { lastDateRange, lastActiveTab }
                    })
                }}
            />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div id="bettingHistoryDetails" className="set-page-content">
                <div className="page-container container">
                    <div className="page-layout-set-vertical">
                        <div className="page-layout-set-horizontal">
                            <Jumbotron className="betting-details-jumbo p-0 d-grid">
                                <Row className="w-100 m-auto dollar-details">
                                    <Col xs='1' className="d-flex p-0">
                                        <img className="m-auto" src={require('../../assets/img/theme/dollar.svg')} />
                                    </Col>
                                    <Col xs='7' className="flex-nowrap line-height-1 p-0 p-l-10">
                                        <div className="general-text f-s-15"><span>{t('TOTAL')}</span></div>
                                        <div className="text-black"><span>-{formatNumber(total)}</span></div>
                                    </Col>
                                    <Col xs='4' className="p-0 m-auto">
                                        {
                                            _location.state?.status === 'Voided' &&
                                            <span className="ticket-status voided pull-right">{t('CANCELLED')}</span>
                                        }
                                        {
                                            _location.state?.status === 'Active' &&
                                            <span className="ticket-status active pull-right">{t('ACTIVE')}</span>
                                        }
                                    </Col>
                                </Row>
                                <Row className="w-100 m-auto dollar-details">
                                    <Col xs='1' className="d-flex p-0">
                                        <img className="m-auto" width={'30px'} height={'30px'} src={require('../../assets/img/theme/date-time.svg')} />
                                    </Col>
                                    <Col xs='11' className="flex-nowrap line-height-1 p-0 p-l-10">
                                        <div className="general-text f-s-15"><span>{t('BY')}</span></div>
                                        <div className="text-black"><span>{date}</span></div>
                                    </Col>
                                </Row>
                            </Jumbotron>

                            <div className="d-flex justify-content-center mb-3">
                                {
                                    _location.state?.status === 'Active' &&
                                    <Button className="cancel-btn theme-btn-5 mr-3" onClick={toggleModal}>{t('CANCEL')}</Button>
                                }
                                <Button className="btn btn-min-width theme-btn-1 mr-3" onClick={() => setRebetModal(true)}>{t('REBET')}</Button>
                                <Button className="btn btn-min-width theme-btn-1" onClick={() => initSharer(getShareContent())}>{t('SHARE')}</Button>
                            </div>
                            <hr />
                            <div className="order-string-container m-b-10">
                                <h1 className="text-white f-s-22">{t('ORDER_STRING')}:</h1>
                                {ticketDetails.orderstring.split('\n').map(str => <p>{str}</p>)}
                            </div>
                            <div className="order-string-container">
                                <h1 className="text-white f-s-22">{t('ORDER_RESPONSE')}:</h1>
                                {ticketDetails.orderresponse.split('\n').map(str => <p>{str.replace('&', '')}</p>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <CancelStateModel isModalOpen={isModalOpen} setModalOpen={setModalOpen} betId={betId} lastDateRange={lastDateRange} />

            <Modal className="modal-brand" isOpen={shareModal} toggle={setShareModal} centered>
                <ModalBody>
                    <strong className="d-block text-center mb-3">{t("SHARE_RECEIPT")}</strong>
                    <div className="btn-share-list">
                        <a target="_blank" className="btn-share btn-share-wa" href={getShareContent(SHARE_PLATFORM.WHATSAPP_SHARE_URL)}>WhatsApp<img src={require('../../assets/img/theme/whatsapp-brands.svg')} /></a>
                        <a target="_blank" className="btn-share btn-telegram" href={getShareContent(SHARE_PLATFORM.TELEGRAM_SHARE_URL)}>Telegram<img src={require('../../assets/img/theme/telegram-brands.svg')} /></a>
                        <CopyToClipboard text={getShareContent(SHARE_PLATFORM.WECHAT_SHARE_URL)}>
                            <a target="_blank" className="btn-share btn-share-wa" href="weixin://">WeChat<img src={require('../../assets/img/theme/wechat-brands.svg')} /></a>
                        </CopyToClipboard>
                    </div>
                    <div className="text-center">
                        <button className="btn theme-btn-2" onClick={() => setShareModal(false)}>{t("CANCEL")}</button>
                    </div>
                </ModalBody>
            </Modal>

            <Modal className="modal-brand bettingModal" isOpen={rebetModal} toggle={setRebetModal} centered>
                <ModalBody>
                    <p className="f-s-20 sfp-bold text-center">{t("REBET")}</p>
                    <p className="f-s-17 sfp-medium text-center theme-text-3">{t("SELECT_BET_MODE_DESC")}</p>
                    <div className='d-flex justify-content-center' style={{ marginBottom: '1rem' }}>
                        <div className="mode-selection" onClick={() => _history.push(WebUrl._BETTING_PAGE, { betId: betId })}>
                            <strong>{t("NORMAL_MODE")}</strong>
                        </div>
                        {
                            _userData.advancedModeEnabled &&
                                <div className="mode-selection" onClick={() => _history.push(WebUrl._BETTING_ADVANCE, { betId: betId })}>
                                    <strong>{t("ADVANCED_MODE")}</strong>
                                </div>
                        }                        
                    </div>
                    <div className="text-center mt-3">
                        <button className="btn theme-btn-2" onClick={() => setRebetModal(false)}>{t("CANCEL")}</button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default BettingHistoryDetails;