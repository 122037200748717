import React from 'react';

const DateInput = (props) => {
    return (
        <input
        className="react-datepicker-input"
        onClick={props.onClick}
        value={props.value}
        type="text"
        readOnly={true}
    />
    );
};

export default DateInput;