import { _IDLE, _BUSY, _ALERT_TYPES, _API_CALLED } from './AppAction';
import { stringIsNullOrEmpty } from '../util/Utility'

const _INITIAL_STATE = {
    isBusy: false,
    message: "Please Wait",
    alertType: _ALERT_TYPES.NONE,
    responseMessage: '',
    onConfirm: null,
    onCancel: null,
    blockMessage: '',
    apiCalledResponse: {}
};

var _busyCount = 0;

/// <summary>
/// Author : -
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _BUSY:
            _busyCount++;
            return {
                ...state,
                isBusy: true,
                message: stringIsNullOrEmpty(action.message) ? _INITIAL_STATE.message : action.message
            }
            break;
        case _IDLE:
            _busyCount = Math.max(--_busyCount, 0);
            return {
                ...state,
                isBusy: (_busyCount != 0),
            }
            break;
        case _ALERT_TYPES.SUCCESS:
            return {
                ...state,
                alertType: _ALERT_TYPES.SUCCESS,
                responseMessage: action.message
            };
            break;
        case _ALERT_TYPES.ERROR:
            return {
                ...state,
                alertType: _ALERT_TYPES.ERROR,
                responseMessage: action.message
            };
            break;
        case _ALERT_TYPES.CONFIRM:
            return {
                ...state,
                alertType: _ALERT_TYPES.CONFIRM,
                responseMessage: action.message,
                onConfirm: action.onConfirm,
                onCancel: action.onCancel
            }
            break;
        case _ALERT_TYPES.NONE:
            return {
                ...state,
                alertType: _ALERT_TYPES.NONE,
                responseMessage: '',
                onConfirm: null,
                onCancel: null
            };
            break;
        case _ALERT_TYPES.BLOCK:
            return {
                ...state,
                responseMessage: '',
                blockMessage: action.message
            }
            break;
        case _API_CALLED:
            return {
                ...state,
                apiCalledResponse: action.data
            };
            break;
        default:
            return state;
            break;
    }
};
