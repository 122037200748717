export const _BUSY = 'busy';
export const _IDLE = 'idle';

export const _ALERT_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    NONE: 'none',
    WARNING: 'warning',
    CONFIRM: 'confirm',
    BLOCK: 'block'
};

export const _API_CALLED = 'setApiCalled';

/// <summary>
/// Author : -
/// </summary>
export const setBusy = (message) => {
    return async dispatch => {
        dispatch({
            type: _BUSY,
            message: message
        });
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to logout
/// </summary>
export const setIdle = () => {
    return async dispatch => {
        dispatch({
            type: _IDLE
        });
    }
}

/// <summary>
/// Author: -
/// </summary>
// decide to pop up as success / error type alert will depend on response status
export const showMessage = (responseStatus, responseMessage) => {
    return async dispatch => {
        dispatch({
            type: responseStatus == true ? _ALERT_TYPES.SUCCESS : _ALERT_TYPES.ERROR,
            message: responseMessage
        });
    }
}

export const showJsonResponseAlert = (responseStatus, responseMessage) => {
    return async dispatch => {
        dispatch({
            type: responseStatus == true ? _ALERT_TYPES.SUCCESS : _ALERT_TYPES.ERROR,
            message: typeof responseMessage === 'object' ? responseMessage.message : responseMessage 
        });
    }
}

export const showBlockAlert = (message) => {
    return async dispatch => {
        dispatch({
            type: _ALERT_TYPES.BLOCK,
            message
        })
    }
}

export const showConfirm = (message, onConfirm = null, onCancel = null) => {
    return async dispatch => {
        dispatch({
            type: _ALERT_TYPES.CONFIRM,
            message,
            onConfirm,
            onCancel
        })
    }
}

/// <summary>
/// Author: -
/// </summary>
// close pop up
export const disposeMessage = () => {
    return async dispatch => {
        dispatch({
            type: _ALERT_TYPES.NONE
        });
    }
}

/// <summary>
/// Author : 
/// </summary>
export const setPerformApiCall = (status, url) => {
    return async dispatch => {
        dispatch({
            type: _API_CALLED,
            data: { success: status, url: url }
        });
    }
}