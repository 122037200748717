import React, { useState, useEffect, useContext } from "react";
import { Jumbotron, Row, Col, Button } from 'reactstrap';
import querystring from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { WebUrl, ApiUrl, ApiKey, StatusCode, APP_TYPE } from '../../util/Constant';
import { formatNumber } from '../../util/Utility';
import { PageSettings } from '../../config/page-settings.js';
import { useDispatch, useSelector } from "react-redux";
import { checkIsLoggedIn } from "../../redux/AuthAction.js";
import { showJsonResponseAlert, showBlockAlert } from "../../redux/AppAction";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import { useTranslation } from 'react-i18next';
import PackageList from '../package/PackagesList';
import ApiEngine from './../../util/ApiEngine';
import { AppConfiguration } from "read-appsettings-json";

const _SWIPER_CONTENT = [
    { id: 'walletStatement', img: require('../../assets/img/theme/wallet-statement.svg'), name: 'WALLET_STATEMENT', navigatePath: WebUrl._WALLET_STATEMENT },
    { id: 'betReport', img: require('../../assets/img/theme/bet-report.svg'), name: 'BET_REPORT', navigatePath: WebUrl._BET_REPORT },
    { id: 'bettingHistory', img: require('../../assets/img/theme/betting-history.svg'), name: 'BETTING_HISTORY', navigatePath: WebUrl._BETTING_HISTORY },
    { id: 'strikeHistory', img: require('../../assets/img/theme/gift.svg'), name: 'STRIKE_HISTORY', navigatePath: WebUrl._STRIKE_HISTORY },
    { id: 'jackpotHistory', img: require('../../assets/img/theme/jackpot-history.svg'), name: 'JACKPOT_HISTORY', navigatePath: WebUrl._JACKPOT_HISTORY, hide: [APP_TYPE._NORMAL] },
    { id: 'viewResults', img: require('../../assets/img/theme/snooker.svg'), name: 'VIEW_RESULTS', navigatePath: WebUrl._RESULT_VIEW },
    { id: 'numberMeaning', img: require('../../assets/img/theme/num-meaning.svg'), name: 'NUMBER_MEANING', navigatePath: WebUrl._NUMBER_MEANING },
    { id: 'help', img: require('../../assets/img/theme/help.svg'), name: 'HELP', navigatePath: WebUrl._HELP }
];

const _PLACE_ORDER_ITEM = [
    { id: 'Normal', bgImg: require('../../assets/img/theme/34d-min.png'), navigatePath: WebUrl._BETTING_PAGE, text: 'NORMAL_MODE' },
    { id: 'Advance', bgImg: require('../../assets/img/theme/34d-min.png'), navigatePath: WebUrl._BETTING_ADVANCE, text: 'ADVANCED_MODE' },
    { id: 'PC', bgImg: require('../../assets/img/theme/34d-min.png'), navigatePath: WebUrl._BETTING_PC_WRAPPER, text: 'PC_MODE' },
];

/// <summary>
/// Author: -
/// </summary>
const Home = props => {
    var { t } = useTranslation();
    var _location = useLocation();
    var _querystring = querystring;

    const _context = useContext(PageSettings);
    const _dispatch = useDispatch();
    const _history = useHistory();
    var _isLoggedIn = useSelector(state => state.authState.isLoggedIn);
    const _userData = useSelector(state => state.authState.userData);

    // to hold the default value of the balance
    const [balanceValue, setBalanceValue] = useState('0.00');
    const [activatedPackage, setActivatedPackage] = useState();
    const [checked, setChecked] = useState(false);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (checked && _isLoggedIn) {
            loadCreditInfo();
            clearInterval(window.creditInfoInterval);
            window.creditInfoInterval = setInterval(() => {
                loadCreditInfo();
            }, 10000);
        }
    }, [_isLoggedIn, checked]);

    useEffect(() => {
        setChecked(false);
        checkLogin();

        _context.handleSetPageSidebar(false);
        _context.handleSetPageHeader(true);
        _context.handleSetPageContentFullWidth(true);
        _context.handleSetPageContentFullHeight(true);
        _context.handlePageToastNotification(false);
        return (() => {
            clearInterval(window.creditInfoInterval);
            _context.handleSetPageSidebar(false);
            _context.handleSetPageHeader(false);
            _context.handleSetPageContentFullWidth(true);
            _context.handleSetPageContentFullHeight(true);
            _context.handlePageToastNotification(false);
        });
    }, [])

    async function checkLogin() {
        let loginData = {
            username: "",
            password: ""
        };

        if (_location.search !== undefined) {
            const data = _querystring.parse(_location.search);
            loginData = {
                username: data.username,
                password: data.password,
                token: data.token
            };
        }

        await _dispatch(checkIsLoggedIn(loginData)).then((res) => {
            setChecked(true);
        })
    }

    /// <summary>
    /// Author: -
    /// Wong - Display unauthorised message and disable interval fn
    /// </summary>
    async function loadCreditInfo() {
        let url = ApiUrl._API_GET_CREDIT;
        await ApiEngine.get(url)
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setBalanceValue(formatNumber(responseJson[ApiKey._API_DATA_KEY].creditLeft));
                }
                else {
                    if (responseJson['code'] == StatusCode.UNAUTHORISED) {
                        _dispatch(showBlockAlert(t("PLEASE_LOGIN_TO_CONTINUE")));
                        clearInterval(window.creditInfoInterval);
                    } else {
                        _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                    }
                }
            });
    }

    const _OPTIONS = {
        dots: false,
        nav: true,
        loop: false,
        items: 4,
        navText: ["<i class='fa fa-angle-left' />", "<i class='fa fa-angle-right' />"],
        margin: 10,
    }

    return (
        <>
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div id="homepage" className="set-page-content">
                <div className="page-container container">
                    <div className="page-layout-set-vertical m-t-10">
                        <div className="page-layout-set-horizontal">
                            <div className="player-wrapper">

                            </div>
                            <Jumbotron className="cr-balance">
                                <h3 className="username-val">
                                    {_userData ? _userData.username : "-"}<br></br>
                                    <span className="tiny-text">Player ID</span>
                                </h3>
                                <h3 className="balance-val">
                                    {balanceValue}<br></br>
                                    <span className="tiny-text">{t("POINTS_BALANCE")}</span>
                                </h3>
                            </Jumbotron>
                            <div id="homepage-swiper">
                                <OwlCarousel className="owl-carousel owl-theme mb-3" options={_OPTIONS}>
                                    {
                                        _SWIPER_CONTENT.filter(c => c.hide != null ? !c.hide.includes(AppConfiguration.Setting().ApplicationConfig.appType) : true).map((item) => {
                                            return <div className="swiper-content-wrapper" onClick={() => {
                                                _history.push(item.navigatePath)
                                            }}
                                                key={item.id}>
                                                <div className="swiper-item-icon"><img src={item.img} alt={item.name}></img></div>
                                                <div className='swiper-item-text theme-text-2'>{(t(item.name)).replace(" ", "\n")}</div>
                                            </div>
                                        })
                                    }
                                </OwlCarousel>
                            </div>
                            <Row>
                                <Col xs={12}>
                                    <h4 className="theme-text">{t("PLACE_ORDER")}</h4>
                                </Col>
                            </Row>
                            <div className="place-order-item-wrapper">
                                {_PLACE_ORDER_ITEM.map((item, index) => {
                                    if (item.id == 'Normal' ||
                                        (item.id == "Advance" && _userData.advancedModeEnabled) ||
                                        (item.id == "PC" && _userData.pcModeEnabled)) {
                                        return (
                                            <div key={item.id} className="place-bet-container">
                                                <div className="place-order-item">
                                                    <img alt="bg" src={item.bgImg}></img>
                                                    {item.id === 'Advance' ?
                                                        <img alt="bg" src={require('../../assets/img/theme/advance.png')}
                                                            style={{ 'position': 'absolute', 'right': 0, 'top': 0, 'background': 'none', 'width': 'unset', 'transform': 'unset' }} />
                                                        : null
                                                    }
                                                    <div className="place-order-misc-item">
                                                        <div class="l-h-1">
                                                            <div className="bet-action-title">{t(item.text)}</div>
                                                        </div>
                                                        <Button
                                                            onClick={() => _history.push({
                                                                pathname: item.navigatePath,
                                                                state: { name: item.id }
                                                            })}
                                                            className="theme-btn-1">{t("BET_NOW")}</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            {
                                _isLoggedIn &&
                                <PackageList activatedPackage={setActivatedPackage} />
                            }
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Home;