import React, { useState, useEffect } from 'react';
import { Input, Label, Form, Button, Row, Col } from 'reactstrap';
import PageNav from '../../components/header/page-navbar.jsx';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import { useForm } from 'react-hook-form';
import { stringIsNullOrEmpty, createMultiPartFormBody, uploadImage } from "../../util/Utility";

import { ApiUrl, ApiKey } from '../../util/Constant.js';
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';

const NumberMeaning = props => {
    const _dispatch = useDispatch();
    const { register, handleSubmit, reset } = useForm();
    const [numberMeaning, setNumberMeaning] = useState([]);

    // Call the controller to get the number meaning from the backend
    const onSubmit = async (data, e) => {
        _dispatch(setBusy());
        let formCollections = {};
        formCollections.searchKeyword = data.searchKeyword;
        await ApiEngine.post(ApiUrl._API_GET_NUMBER_MEANING, createMultiPartFormBody(formCollections))
            .then((responseJson) => {
                setNumberMeaning(responseJson[ApiKey._API_DATA_KEY]);
            });
        _dispatch(setIdle());
    }
    var { t } = useTranslation();

    return <>
        <PageNav pageTitle={t('NUMBER_MEANING')} showDate={false} showRefreshBtn onRefresh={() => { reset(); setNumberMeaning("") }} />
        <div className="bg-betting-history">
            <div className="bg-1"></div>
        </div>
        <div id="numberMeaning" className="set-page-content">
            <div className="page-container container">
                <div className="page-layout-set-vertical">
                    <div className="page-layout-set-horizontal">
                        <div className="content-wrapper">

                            <form style={{ margin: "1rem 0" }} onSubmit={handleSubmit(onSubmit)}>
                                <Row style={{ 'position': 'relative', width: '100%', margin: 'auto' }}>
                                    <input placeholder={t('SEARCH') + "..."} className="form-control form-control-lg" id="searchKeyword" name="searchKeyword" type="text" ref={register()} />
                                    <button className="search-btn theme-btn-4 btn btn-lg" type="submit">{t('SEARCH')}</button>
                                </Row>
                            </form>

                            {
                                numberMeaning.length > 0 ?
                                    <table className="table table-number-meaning">
                                        <thead>
                                            <tr>
                                                <td></td>
                                                <th>{t('TUA_PEK_KONG')}</th>
                                                <th>{t('GUAN_YING')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                numberMeaning.map((num) => {
                                                    return <tr>
                                                        <th>{num.number}</th>
                                                        <td>
                                                            <div className="number-meaning-wrapper">
                                                                <img src={`http://www.jjlotto.com/images/da_bo_gong/${num.number}.png`} style={{ width: "100px", height: "100px" }} />
                                                                <div className="f-s-15 theme-text-4 sfp-regular" style={{ lineHeight: 0.8 }}>{num.tpk_chn}<br />{num.tpk_eng}</div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="number-meaning-wrapper">
                                                                <img src={`http://www.jjlotto.com/images/guan_yin/${num.number}.png`} style={{ width: "100px", height: "100px" }} />
                                                                <div className="f-s-15 theme-text-4 sfp-regular" style={{ lineHeight: 0.8 }}>{num.gy_chn}<br />{num.gy_eng}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    :
                                    <div className="empty-state-text">{t("NO_RESULT_FOUND")}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
export default NumberMeaning;