import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import { WebUrl } from '../../util/Constant';
import { useTranslation } from 'react-i18next';

const BettingStateModal = props => {
    const _classname = "bettingModal"
    const _history = useHistory();
    const { isModalOpen, setModalOpen, fromConfirmationFooter } = props;
    const toggle = () => setModalOpen(!isModalOpen);
    var { t } = useTranslation();

    return (
        <Modal isOpen={isModalOpen} toggle={toggle} className={_classname} centered>
            <ModalBody className="text-center">
                <p className="f-s-20 sfp-bold">{t("CONFIRM_LEAVE")}</p>
                <p className="f-s-17 theme-text-3 sfp-medium">{t("CONFIRM_LEAVE_ORDER_LINE1")} <br />{t("CONFIRM_LEAVE_ORDER_LINE2")}</p>
                <div className="d-flex justify-content-center">
                    <Button onClick={toggle} className="theme-btn-2 btn btn-md m-r-5">{t("CANCEL")}</Button>
                    <Button onClick={() => { _history.push('/') }} className="theme-btn-1 btn btn-md m-l-5">{t("LEAVE")}</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default BettingStateModal;