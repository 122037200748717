import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Images, POOL_SHORT_CODE, APP_TYPE, LottoBrand } from '../../util/Constant';
import classname from 'classnames';
import { AppConfiguration } from "read-appsettings-json";
import { useSelector } from 'react-redux';

const _BETTING_BRANDS_SWIPER = [
    { poolId: 0, shortCode: POOL_SHORT_CODE._MAGNUM, imgSrc: Images._MAGNUM_LOGO_SQUARE, active: false, appType: APP_TYPE._NORMAL },
    { poolId: 1, shortCode: POOL_SHORT_CODE._DAMACAI, imgSrc: Images._DAMACAI_INVERSED_LOGO, active: false, appType: APP_TYPE._NORMAL },
    { poolId: 2, shortCode: POOL_SHORT_CODE._TOTO, imgSrc: Images._TOTO_INVERSED_LOGO, active: false, appType: APP_TYPE._NORMAL },
    { poolId: 3, shortCode: POOL_SHORT_CODE._SG, imgSrc: Images._SG_LOTTO_SQUARE, active: false, appType: APP_TYPE._NORMAL },
    { poolId: 4, shortCode: POOL_SHORT_CODE._88, imgSrc: Images._88_LOTTO_LOGO_SQUARE, active: false, appType: APP_TYPE._NORMAL },
    //{ poolId: 5, shortCode: POOL_SHORT_CODE._STC, imgSrc: Images._STC_LOTTO_LOGO, active: false, appType: APP_TYPE._NORMAL },
    { poolId: 6, shortCode: POOL_SHORT_CODE._CASH_SWEEP, imgSrc: Images._DA_CASH_WAN_LOGO, active: false, appType: APP_TYPE._NORMAL },
    { poolId: 7, shortCode: POOL_SHORT_CODE._GD, imgSrc: Images._GD_LOTTO_LOGO, active: false, appType: APP_TYPE._GD },
    { poolId: 8, shortCode: POOL_SHORT_CODE._9LOTTO, imgSrc: Images._9_LOTTO_LOGO, active: false, appType: APP_TYPE._9LOTTO },
];

const _BETTING_BRANDS_SWIPER_56D = [
    { poolId: 2, shortCode: POOL_SHORT_CODE._TOTO, imgSrc: Images._TOTO_INVERSED_LOGO, active: false, appType: APP_TYPE._NORMAL },
    { poolId: 7, shortCode: POOL_SHORT_CODE._GD, imgSrc: Images._GD_LOTTO_LOGO, active: false, appType: APP_TYPE._GD },
    { poolId: 8, shortCode: POOL_SHORT_CODE._9LOTTO, imgSrc: Images._9_LOTTO_LOGO, active: false, appType: APP_TYPE._9LOTTO },
];

/// <summary>
/// Author: -
/// </summary>
const PoolPickSwiper = props => {
    const {
        selectedPoolId,
        setSelectedPoolId,
        betType,
        calculate
    } = props;

    const [poolActive, setPoolActive] = useState([]);
    const [finalPoolList, setFinalPoolList] = useState([]);
    const _userData = useSelector(state => state.authState.userData);

    const toggleIconHandler = (poolId) => {
        var newArr = []
        poolActive.map((item, index) => {
            if (item.poolId === poolId) {
                item.active = !item.active
            }
            newArr.push(item)
        })
        setPoolActive(newArr);
        setSelectedPoolId(newArr);
        calculate();
    }

    useEffect(() => {
        let appType = AppConfiguration.Setting().ApplicationConfig.appType;

        // 4D pool list 
        let poolList = [];

        // 2D, 3D, 4D pool list
        if (betType != "5D" && betType != "6D") {
            poolList = _BETTING_BRANDS_SWIPER.filter(p => 
                (
                    p.appType == appType
                    && 
                    (p.poolId <= LottoBrand._DA_CASH_WAN && _userData.normalPoolsEnabled)
                )
                || 
                (p.shortCode == POOL_SHORT_CODE._GD && (_userData.combineGdLotto || _userData.gdPoolEnabled))
                || 
                (p.shortCode == POOL_SHORT_CODE._9LOTTO && (_userData.combine9Lotto || _userData.l9PoolEnabled))
            );
        }
        // 5D pool list
        else if (betType == "5D" && appType == APP_TYPE._NORMAL && _userData.normalPoolsEnabled) {
            // Only toto available in 5D
            poolList.push({ ..._BETTING_BRANDS_SWIPER_56D[0]}); // toto
        }
        // 6D pool list
        else if (betType == "6D") {
            if (appType == APP_TYPE._NORMAL && _userData.normalPoolsEnabled) {
                poolList.push({ ..._BETTING_BRANDS_SWIPER_56D[0]}); // toto
            }

            if (
                (appType == APP_TYPE._NORMAL ||  appType == APP_TYPE._GD)
                &&
                (_userData.combineGdLotto || _userData.gdPoolEnabled)
            ) {
                poolList.push({ ..._BETTING_BRANDS_SWIPER_56D[1]}); // GD
            }

            if (
                (appType == APP_TYPE._NORMAL ||  appType == APP_TYPE._9LOTTO)
                &&
                (_userData.combine9Lotto || _userData.l9PoolEnabled)
            ) {
                poolList.push({ ..._BETTING_BRANDS_SWIPER_56D[2]}); // 9L
            }
        }
        
        if (poolList.length > 0) { 
            poolList[0].active = true; 
        }

        console.log(poolList, betType, appType);   

        setPoolActive(poolList);
        setSelectedPoolId(poolList);
        setFinalPoolList(poolList);
    }, [_BETTING_BRANDS_SWIPER, _BETTING_BRANDS_SWIPER_56D, betType])

    return (
        finalPoolList.length > 0 &&
        <div className="pool-picker-wrapper">
            {
                finalPoolList.map((item, index) => {
                    return <div key={item.poolId} className={classname("text-center pool-selection", { "active": item.active })}
                        onClick={() => { toggleIconHandler(item.poolId) }}>
                        <img className="brand-icon" src={item.imgSrc}></img>
                    </div>
                })
            }
        </div>
    )
}

export default PoolPickSwiper;
