import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import PageNav from '../../components/header/page-navbar.jsx';
import { Panel, PanelBody } from '../../components/panel/panel';
import { ApiKey, ApiUrl, AlertTypes, EatType, BetType, _SELECT_DEBOUNCE_MS, WebUrl } from "../../util/Constant";
import { createFormBody, showMessage, stringIsNullOrEmpty, numberWithCurrencyFormat } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import Input from '../../components/custom/Input';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import moment from 'moment';
import WebOrderOnePanel from '../../components/content/Pcbet';
import AsyncSelect from 'react-select/async';
import { formatNumber } from '../../util/Utility.js';
import { showJsonResponseAlert, setBusy, setIdle, showConfirm } from "../../redux/AppAction";
import _ from 'lodash';

/// <summary>
/// Author: -
/// </summary>
const PcbetWrapper = props => {
    const _location = useLocation();
    const _history = useHistory();
    const _dispatch = useDispatch();

    const { t } = useTranslation();
    const { control, handleSubmit, errors, setValue, register, watch } = useForm();
    var _userData = useSelector(state => state.authState.userData);
    const [poolCutOff, setPoolCutOff] = useState([]);
    const [availablePool, setAvailablePool] = useState([]);
    const [maxLine, setMaxLine] = useState(20);
    const [selectedDate, setSelectedDate] = useState([]);
    const [remark, setRemark] = useState('');
    const [customerPhone, setCustomerPhone] = useState('');
    const [bettingAgent, setBettingAgent] = useState();
    const [userInfo, setUserInfo] = useState();
    const [receipt, setReceipt] = useState('');
    const [isAutoTicket, setIsAutoTicket] = useState(false);
    const [defaultUserInfo, setDefaultUserInfo] = useState({});
    const [creditBalance, setCreditBalance] = useState(0);
    const [isBettingState, setBettingState] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);


    let _BET_TYPE_OPTION = [
        { label: 'Normal', value: BetType._NORMAL },
        { label: 'Box', value: BetType._BOX },
        { label: 'IBox', value: BetType._IBOX },
        { label: 'P-H', value: BetType._PH },
        { label: 'P-B', value: BetType._PB },
        { label: 'Reversed', value: BetType._R },
    ];
    let _BETTING_OPTION = [
        { label: '3D/4D', value: 'd43' },
        { label: '2D', value: EatType._2D },
        { label: '3D', value: EatType._3D },
        { label: '4D', value: EatType._4D },
        { label: '5D', value: EatType._5D },
        { label: '6D', value: EatType._6D }
    ];

    let _SETTINGS_KEY_MAXLINE = 80;

    const _SUB_BETTING = {};
    _SUB_BETTING[_BETTING_OPTION[0].value] = [
        {
            name: 'Big',
            key: `d4Big`,
            total: 0,
            maxLength: 4
        },
        {
            name: 'Small',
            key: `d4Small`,
            total: 0,
            maxLength: 4
        },
        {
            name: '3C',
            key: `${_BETTING_OPTION[2].value}C`,
            total: 0,
            maxLength: 3,
            allowExceed: true
        },
        {
            name: '3A',
            key: `${_BETTING_OPTION[2].value}A`,
            total: 0,
            maxLength: 3,
            allowExceed: true
        },
        {
            name: '4A',
            key: `${_BETTING_OPTION[3].value}A`,
            total: 0,
            maxLength: 4
        }
    ];
    _SUB_BETTING[_BETTING_OPTION[1].value] = [
        {
            name: '2A',
            key: `${_BETTING_OPTION[1].value}A`,
            total: 0,
            maxLength: 2
        },
        {
            name: '2C',
            key: `${_BETTING_OPTION[1].value}C`,
            total: 0,
            maxLength: 2
        },
        {
            name: '2SB',
            key: `${_BETTING_OPTION[1].value}SB`,
            total: 0,
            maxLength: 2
        },
        {
            name: '2SC',
            key: `${_BETTING_OPTION[1].value}SC`,
            total: 0,
            maxLength: 2
        },
        {
            name: '2SD',
            key: `${_BETTING_OPTION[1].value}SD`,
            total: 0,
            maxLength: 2
        },
        {
            name: '2SE',
            key: `${_BETTING_OPTION[1].value}SE`,
            total: 0,
            maxLength: 2
        }
    ];
    _SUB_BETTING[_BETTING_OPTION[2].value] = [
        {
            name: '3A',
            key: `${_BETTING_OPTION[2].value}A`,
            total: 0,
            maxLength: 3
        },
        {
            name: '3C',
            key: `${_BETTING_OPTION[2].value}C`,
            total: 0,
            maxLength: 3
        },
        {
            name: '3SB',
            key: `${_BETTING_OPTION[2].value}SB`,
            total: 0,
            maxLength: 3
        },
        {
            name: '3SC',
            key: `${_BETTING_OPTION[2].value}SC`,
            total: 0,
            maxLength: 3
        },
        {
            name: '3SD',
            key: `${_BETTING_OPTION[2].value}SD`,
            total: 0,
            maxLength: 3
        },
        {
            name: '3SE',
            key: `${_BETTING_OPTION[2].value}SE`,
            total: 0,
            maxLength: 3
        }
    ];
    _SUB_BETTING[_BETTING_OPTION[3].value] = [
        {
            name: '4A',
            key: `${_BETTING_OPTION[3].value}A`,
            total: 0,
            maxLength: 4
        },
        {
            name: '4SB',
            key: `${_BETTING_OPTION[3].value}SB`,
            total: 0,
            maxLength: 4
        },
        {
            name: '4SC',
            key: `${_BETTING_OPTION[3].value}SC`,
            total: 0,
            maxLength: 4
        },
        {
            name: '4SD',
            key: `${_BETTING_OPTION[3].value}SD`,
            total: 0,
            maxLength: 4
        },
        {
            name: '4SE',
            key: `${_BETTING_OPTION[3].value}SE`,
            total: 0,
            maxLength: 4
        },
        {
            name: '4C',
            key: `${_BETTING_OPTION[3].value}C`,
            total: 0,
            maxLength: 4
        },
    ];
    _SUB_BETTING[_BETTING_OPTION[4].value] = [
        {
            name: '5D',
            key: _BETTING_OPTION[4].value,
            total: 0,
            maxLength: 5
        }
    ];
    _SUB_BETTING[_BETTING_OPTION[5].value] = [
        {
            name: '6D',
            key: _BETTING_OPTION[5].value,
            total: 0,
            maxLength: 6
        }
    ];
    const [betTotal, setBetTotal] = useState(_SUB_BETTING[_BETTING_OPTION[0]['value']]);
    const [selectedEatType, setSelectedEatType] = useState(_BETTING_OPTION[0]['value']);

    /// <summary>
    /// Author: -
    /// </summary>
    const zeroPad = (num, places) => String(num).padStart(places, '0')

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        getUserDetail();
        getPoolCutOffTime();
        getPoolSchedules();
        getMaxLineBet();
        loadCredit();
    }, []);

    /// <summary>
    /// Author : Wong
    /// </summary>
    const loadCredit = () => {
        ApiEngine.get(ApiUrl._API_GET_CREDIT)
            .then((response) => {
                setCreditBalance(response[ApiKey._API_SUCCESS_KEY] ? formatNumber(response[ApiKey._API_DATA_KEY].creditLeft) : "-");
            })
    }

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(bettingAgent)) {
            getUserInfo();
        }

        async function getUserInfo() {
            var responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_DETAIL_BY_ID + "?&userId=" + bettingAgent);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setUserInfo(responseJson[ApiKey._API_DATA_KEY]);
            }
            else {
                showMessage({
                    type: AlertTypes._ERROR,
                    content: t(responseJson[ApiKey._API_MESSAGE_KEY])
                });
            }
        }
    }, [bettingAgent]);


    /// <summary>
    /// Author: -
    /// </summary>
    async function getPoolCutOffTime() {
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_POOLS_SELECTION);
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setPoolCutOff(responseJson[ApiKey._API_DATA_KEY]);
            console.log(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            });
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function getMaxLineBet() {
        setMaxLine(_SETTINGS_KEY_MAXLINE);
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function getPoolSchedules() {
        await ApiEngine.get(`${ApiUrl._API_GET_POOL_SCHEDULE}`)
            .then((responseJson) => {
                setAvailablePool(responseJson[ApiKey._API_DATA_KEY]);
                console.log(responseJson[ApiKey._API_DATA_KEY]);
            });
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function updateSelectedDate(date) {
        if (selectedDate.filter(selectedDate => selectedDate == date).length) {
            setSelectedDate(selectedDate.filter(selectedDate => selectedDate != date));
        }
        else {
            setSelectedDate([...selectedDate, date]);
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function submitBet(orderData, orderMethod) {
        var errorMessage = '';
        let isSuccess = false;

        if (orderData.length < 1) {
            errorMessage = t('NO_ORDER_DATA_FOUND');
        }
        else if (selectedDate.length < 1) {
            errorMessage = t('PLEASE_SELECT_A_BETDATE');
        }
        else if (isAutoTicket && selectedDate.length > 1) {
            errorMessage = t('AUTO_TICKET_MORE_THAN_ONE_DAY');
        }

        if (!stringIsNullOrEmpty(errorMessage)) {
            showMessage({
                type: AlertTypes._ERROR,
                content: errorMessage
            });
            return;
        }

        // here we need to massage the data back into the data format that needed for the
        // betting that used by the existing cash mmarket system
        // 1st:
        // put in the dates
        var name = selectedEatType;
        var activeDateArray = [];
        var betList = [];
        var activeDateArray = [];

        try {
            // set the date
            selectedDate.map((d) => {
                activeDateArray.push({
                    date: d.substring(6, 8) + "/" + d.substring(4, 6) + "/" + d.substring(0, 4),
                    label: d,
                    value: d
                })
            });

            // remark already set so OK

            // loop to massage the order data into the format that needed
            for (let i = 0; i < orderData.length; i++) {
                var id = orderData[i].id;
                var number = orderData[i].number;
                var betType = [];
                var pattern = "NORMAL";
                var poolList = [];
                var poolShortCodes = [];
                var type = '3.4D';

                console.log(orderData[i]);

                // 34D
                if (orderData[i].d4Big !== undefined) {
                    betType.push({
                        name: "B",
                        amount: parseFloat(orderData[i].d4Big)
                    });
                }

                if (orderData[i].d4Small !== undefined) {
                    betType.push({
                        name: "S",
                        amount: parseFloat(orderData[i].d4Small)
                    });
                }

                if (orderData[i].d3A !== undefined) {
                    betType.push({
                        name: "3A",
                        amount: parseFloat(orderData[i].d3A)
                    });
                }

                if (orderData[i].d3C !== undefined) {
                    betType.push({
                        name: "3C",
                        amount: parseFloat(orderData[i].d3C)
                    });
                }

                if (orderData[i].d4A !== undefined) {
                    betType.push({
                        name: "4A",
                        amount: parseFloat(orderData[i].d4A)
                    });
                }

                // 4D
                if (orderData[i].d4SB !== undefined) {
                    betType.push({
                        name: "4SB",
                        amount: parseFloat(orderData[i].d4SB)
                    });
                }

                if (orderData[i].d4SC !== undefined) {
                    betType.push({
                        name: "4SC",
                        amount: parseFloat(orderData[i].d4SC)
                    });
                }

                if (orderData[i].d4SD !== undefined) {
                    betType.push({
                        name: "4SD",
                        amount: parseFloat(orderData[i].d4SD)
                    });
                }

                if (orderData[i].d4SE !== undefined) {
                    betType.push({
                        name: "4SE",
                        amount: parseFloat(orderData[i].d4SE)
                    });
                }

                if (orderData[i].d4C !== undefined) {
                    betType.push({
                        name: "4C",
                        amount: parseFloat(orderData[i].d4C)
                    });
                }

                // 3D
                if (orderData[i].d3SB !== undefined) {
                    betType.push({
                        name: "3SB",
                        amount: parseFloat(orderData[i].d3SB)
                    });
                }

                if (orderData[i].d3SC !== undefined) {
                    betType.push({
                        name: "3SC",
                        amount: parseFloat(orderData[i].d3SC)
                    });
                }

                if (orderData[i].d3SD !== undefined) {
                    betType.push({
                        name: "3SD",
                        amount: parseFloat(orderData[i].d3SD)
                    });
                }

                if (orderData[i].d3SE !== undefined) {
                    betType.push({
                        name: "3SE",
                        amount: parseFloat(orderData[i].d3SE)
                    });
                }

                // 2D
                if (orderData[i].d2SA !== undefined) {
                    betType.push({
                        name: "2SA",
                        amount: parseFloat(orderData[i].d2SA)
                    });
                }

                if (orderData[i].d2SB !== undefined) {
                    betType.push({
                        name: "2SB",
                        amount: parseFloat(orderData[i].d2SB)
                    });
                }

                if (orderData[i].d2SC !== undefined) {
                    betType.push({
                        name: "2SC",
                        amount: parseFloat(orderData[i].d2SC)
                    });
                }

                if (orderData[i].d2SD !== undefined) {
                    betType.push({
                        name: "2SD",
                        amount: parseFloat(orderData[i].d2SD)
                    });
                }

                if (orderData[i].d2SE !== undefined) {
                    betType.push({
                        name: "2SE",
                        amount: parseFloat(orderData[i].d2SE)
                    });
                }

                // 5/6D
                if (orderData[i].d5D !== undefined) {
                    betType.push({
                        name: "5D",
                        amount: parseFloat(orderData[i].d5D)
                    });
                }

                if (orderData[i].d6D !== undefined) {
                    betType.push({
                        name: "6D",
                        amount: parseFloat(orderData[i].d6D)
                    });
                }

                if (orderData[i].betType === undefined) {
                    pattern = "NORMAL";
                }
                else if (orderData[i].betType === BetType._NORMAL)
                {   
                    pattern = "NORMAL";
                }
                else if (orderData[i].betType === BetType._IBOX) {
                    pattern = "IBOX";
                }
                else if (orderData[i].betType === BetType._BOX) {
                    pattern = "BOX";
                }
                else if (orderData[i].betType === BetType._PH) {
                    pattern = "P-H";
                }
                else if (orderData[i].betType === BetType._PB) {
                    pattern = "P-B";
                }
                else if (orderData[i].betType === BetType._R) {
                    pattern = "R";
                }

                if (orderData[i].MAGNUM !== undefined) {
                    poolList.push(0);
                    poolShortCodes.push("M");
                }

                if (orderData[i].PMP !== undefined) {
                    poolList.push(1);
                    poolShortCodes.push("P");
                }

                if (orderData[i].TOTO !== undefined) {
                    poolList.push(2);
                    poolShortCodes.push("T");
                }

                if (orderData[i].SINGAPORE !== undefined) {
                    poolList.push(3);
                    poolShortCodes.push("S");
                }

                if (orderData[i].SABAH !== undefined) {
                    poolList.push(4);
                    poolShortCodes.push("B");
                }

                if (orderData[i].STC !== undefined) {
                    poolList.push(5);
                    poolShortCodes.push("D");
                }

                if (orderData[i].SARAWAK !== undefined) {
                    poolList.push(6);
                    poolShortCodes.push("W");
                }

                if (orderData[i].GD !== undefined) {
                    poolList.push(7);
                    poolShortCodes.push("G");
                }

                if (orderData[i]['9LOTTO'] !== undefined) {
                    poolList.push(8);
                    poolShortCodes.push("E");
                }

                // check the bet type
                switch (selectedEatType) {
                    case 'd43':
                        type = '3.4D';
                        break;
                    case 'd4':
                        type = '4D';
                        break;
                    case 'd3':
                        type = '3D';
                        break;
                    case 'd2':
                        type = '2D';
                        break;
                    case 'd5':
                        type = '5D';
                        break;
                    case 'd6':
                        type = '6D';
                        break;
                }

                betList.push({
                    id: id,
                    number: number,
                    betType: betType,
                    pattern: pattern,
                    poolList: poolList,
                    poolShortCodes: poolShortCodes,
                    totalAmount: 0,
                    type: type
                });
            }

            var summaryItem =
            {
                name: selectedEatType,
                dates: activeDateArray,
                betList: betList
            };

            console.log(summaryItem);

            if (summaryItem != null) {
                let url = ApiUrl._API_MAKE_ORDER;

                await ApiEngine.post(url, summaryItem)
                    .then((responseJson) => {
                        _dispatch(setIdle());
                        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                            sessionStorage.removeItem("OrderCart");
                            _history.push(WebUrl._BET_SUCCESSFUL, { receipt: responseJson[ApiKey._API_DATA_KEY], betUrl: WebUrl._BETTING_PC_WRAPPER });
                        }
                        else {
                            _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY] ?? "Failed to place bet"));
                        }
                    });
            } else {
                _dispatch(setIdle());
            }
        } catch (error) {
            _dispatch(setIdle());
            _dispatch(showJsonResponseAlert("Error", error));
        }
        
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function getUserDetail() {
        setDefaultUserInfo({ value: _userData.userId, label: _userData.username });
    }

    return (
        <>
            <PageNav
                pageTitle={t("WEB_ORDER")}
                pageBackHandler={() => _history.push('/') }
                headerOption={
                    <div className="credit-wrapper">
                        {_userData ? _userData.username : "-"}
                        <span className="theme-btn-1 btn-xs badge-credit">
                            <img src={require("../../assets/img/theme/chip.svg")} />
                            {creditBalance}
                        </span>
                    </div>
                }
            />
            <div className="row">
                <div className="col-md-2">
                    &nbsp;<br /> &nbsp;
                    <Controller
                        defaultValue={_BETTING_OPTION[0]['value']}
                        name="bettingOption"
                        render={({ onChange, value, name }) => {
                            return (
                                <Select
                                    isSearchable={false}
                                    name={name}
                                    options={_BETTING_OPTION}
                                    value={_BETTING_OPTION.find(x => x['value'] == value)}
                                    onChange={(value) => {
                                        onChange(value['value']);
                                        setSelectedEatType(value['value']);
                                        setBetTotal(_SUB_BETTING[value['value']]);
                                    }} />
                            )
                        }}
                        control={control} /><br />
                </div>
            </div>
            <div className="row weborder-info-wrapper mb-2">
                <div className="col-md-6">
                    <div className="weborder-right-wrapper" style={{ 'grid-template-columns': `repeat(${!props.isWebOrderTwo ? '3' : '2'}, 1fr)` }}>                        
                        <div className="form-group d-flex mb-2">                        
                            <Panel className="flex-1">
                                <PanelBody>
                                    <label>{t('BET_DATE') + '*'}</label>
                                    <div style={{ 'justifyContent': 'space-evenly' }}>
                                        {
                                            availablePool.map((item, index) => {
                                                return (
                                                    <button key={index} onClick={() => { updateSelectedDate(item.drawDate) }}
                                                        className={selectedDate.filter(selectedDate => selectedDate == item.drawDate).length ? 'btn btn-primary-outline' : 'btn btn-light'}
                                                        style={{
                                                            "minWidth": "100px",
                                                            "minHeight": "50px",
                                                            "marginBottom": "12px",
                                                            "margin-right": "8px",
                                                            "fontSize": ".8rem"
                                                        }}>
                                                        {moment(item.drawDate).format('DD/MM') + ' (' + moment(item.drawDate).format('ddd') + ')'}
                                                    </button>
                                                );
                                            })
                                        }
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                        {
                            !props.isWebOrderTwo &&
                            <div className="form-group d-flex mb-2">
                                <Panel className="flex-1">
                                    <PanelBody>
                                        <label>{t('BET_TOTAL')}</label>
                                        <table className="table table-bet-total mb-0">
                                            <tbody>
                                                {
                                                    betTotal.map((item, index) => {
                                                        return <tr>
                                                            <td>{item.name}</td>
                                                            <th>{numberWithCurrencyFormat(item.total)}</th>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td style={{ 'minWidth': '35px', 'fontSize': '15px', 'color': '#1C7EF7' }}>{t('TOTAL')}</td>
                                                    <th style={{ 'minWidth': '35px', 'fontSize': '15px', 'color': '#1C7EF7' }}>{numberWithCurrencyFormat(betTotal.reduce((sum, obj) => obj.total + sum, 0))}</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </PanelBody>
                                </Panel>
                            </div>
                        }
                        <div className="form-group d-flex bet-agent-wrapper mb-2">
                            <Panel className="flex-1">
                                <PanelBody>
                                    <div className="form-group">
                                        <div className='form-group'>
                                            <Input
                                                style={{ fontWeight: 'bold' }}
                                                disabled={true}
                                                value={_userData.username}
                                                label={t('BETTING_AGENT')}
                                            />
                                        </div>                          
                                    </div>
                                    <div className="form-group">
                                        <div className='form-group'>
                                            <Input
                                                style={{ color: 'red', fontWeight: 'bold' }}
                                                disabled={true}
                                                value={creditBalance}
                                                label={t('CREDIT_LEFT')}
                                            />
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <Controller
                                            control={control}
                                            name="phoneNo"
                                            value={customerPhone}
                                            render={({ value, name }) => (
                                                <Input
                                                    name={name}
                                                    value={value}
                                                    onInput={value => {
                                                        setCustomerPhone(value);
                                                    }}
                                                    label={t('CUSTOMER_TELEPHONE')}
                                                    placeholder={'0123456789'}
                                                    errors={errors}
                                                    type="number"
                                                    autocomplete="off"
                                                />
                                            )}
                                            rules={{ required: true }}
                                        />
                                    </div>
                                </PanelBody>
                            </Panel>
                        </div>
                    </div>
                    <div className="form-group mb-0">
                        <Panel>
                            <PanelBody>
                                <label>{t('REMARK')}</label>
                                <textarea
                                    className="form-control form-control-lg"
                                    autoComplete="off"
                                    rows="2"
                                    onChange={(e) => { setRemark(e.target.value) }} />
                            </PanelBody>
                        </Panel>
                    </div>
                </div>
                <div className="col-5">
                    <div className="form-group mb-2">
                        <Panel className="mb-0">
                            <PanelBody>
                                <label>{t('CUT_OFF_HOURS_MINUTES')}</label>
                                <div className="pool-cutoff-wrapper" style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                                    {
                                        poolCutOff.map((pool, index) => {
                                            return (
                                                <figure key={index}>
                                                    <img src={require("../../assets/img/pool-image/" + pool.poolImage )} className="poolCutOffImage" title={pool.name} />
                                                    <figcaption style={{ 'fontWeight': 'bold', 'fontSize': '.8rem' }}>{pool.cutoffHour + " : " + zeroPad(pool.cutoffMinutes, 2)}</figcaption>
                                                </figure>
                                            );
                                        })
                                    }
                                </div>
                            </PanelBody>
                        </Panel>
                    </div>
                    <div className="form-group mb-0 pool-schedule-wrapper">
                        {
                            availablePool.map((item, index) => {
                                return (
                                    <div key={index} className="availablePool" style={index % 2 != 0 ? { 'backgroundColor': '#fff' } : { 'backgroundColor': '#e5e4e2' }}>
                                        <div style={{ "display": "flex" }}>
                                            <div className="availablePoolDate" style={index % 2 == 0 ? { 'backgroundColor': '#fff' } : { 'backgroundColor': '#e5e4e2' }}>
                                                <font style={{ "color": "#3D3B4F" }}>
                                                    {moment(item.drawDate).format('DD MMM YYYY') + ' (' + moment(item.drawDate).format('ddd') + ')'}
                                                </font>
                                            </div>
                                            <div className="availablePoolList">
                                                {
                                                    item.pools.map((pool, index) => {
                                                        return (
                                                            <img key={index} src={require("../../assets/img/pool-image/" + pool.poolImage)} className="poolCutOffImage" title={pool.name} />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-8">
                    <div className="form-group">
                        {
                            <WebOrderOnePanel
                                selectedEatType={selectedEatType}
                                option={_SUB_BETTING[selectedEatType]}
                                poolSelection={poolCutOff}
                                maxLine={maxLine}
                                betTypeOption={_BET_TYPE_OPTION}
                                totalDay={selectedDate.length}
                                onDataChanged={(data) => { setBetTotal(data); }}
                                numberLength={selectedEatType.toString().charAt(1)}
                                onBetSubmit={(orderData, method) => submitBet(orderData, method)}
                                isAutoTicket={isAutoTicket}
                                setAutoTicket={() => setIsAutoTicket(!isAutoTicket)} />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default PcbetWrapper;
