import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';

/// <summary>
/// Author: -
/// </summary>
const CustomToastComponent = props => {
    return (
        <div className="toast-notification-content-wrapper">
            <div className="toast-noti-text">{props.content}</div>
        </div>
    )
}

/// <summary>
/// Author: -
/// </summary>
const ToastNotification = props => {
    const _history = useHistory();
    const [alertNotify, setAlertNotify] = useState([]);
    const { addToast } = useToasts();

    /// <summary>
    /// Author: -
    /// </summary>
    const setAppeareance = (item) => {
        switch (item.notificationType) {
            case 'success':
                return 'success'
            case 'info':
                return 'info'
            case 'warning':
                return 'warning'
            case 'error':
                return 'error'
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const callback = (id) => { return };

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (alertNotify.length > 0) {
            alertNotify.map((item, index) => {
                addToast(<CustomToastComponent content={item.headline} />, {
                    appearance: setAppeareance(item)
                }, callback)
            })
            setAlertNotify([]);
        }
    }, [alertNotify])
    return (null)
}

export default ToastNotification;
