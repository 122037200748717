import { createFormBody } from "../util/Utility";
import { ApiKey, ApiUrl } from "../util/Constant";
import { setBusy, setIdle, showMessage } from "./AppAction";
import { fetch } from 'whatwg-fetch';

// Login and Authentication 
export const _LOGIN_SUCCESS = 'LoginSuccess';
export const _LOGIN_FAILED = 'LoginFailed';
export const _LOGOUT = 'Logout';
export const _IS_LOGGED_IN = 'IsLoggedIn';

// Own credits information
export const _GET_OWN_CREDIT = 'GetOwnCredit';

/// <summary>
/// Author : EastNoName
/// This is to get the wallet balance from the cash market (Seamless)
/// </summary>
export function getOwnCredit() {
    return async (dispatch, getState) => {
        var userData = getState().authState.userData;

        if (userData) {
            /*var responseJson = await ApiEngine.get(ApiUrl._API_GET_ACCOUNT + "?id=" + userData.userId);
            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                var shopData = responseJson[ApiKey._API_DATA_KEY];

                dispatch({
                    creditLimit: shopData.shopProfile ? shopData.shopProfile.creditLimit : 0,
                    type: _GET_SHOP_CREDIT
                });
            }*/
        }
    }
}

/// <summary>
/// Author : -
/// expose login action as function to be called from classes
/// </summary>
export function performLogin(username, password, token) {
    return async dispatch => {
        let params = {
            "username": username,
            "password": password,
            "token": token
        };

        let formBody = createFormBody(params);
        dispatch(setBusy());

        // calling the frontend controller to perform login
        const response = await fetch(ApiUrl._API_LOGIN, {
            method: ApiKey._API_POST,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            },
            body: formBody
        });

        const responseJson = await response.json();

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            localStorage.setItem("_t", token);

            var userData = responseJson[ApiKey._API_DATA_KEY];
            dispatch({
                type: _LOGIN_SUCCESS,
                userData: userData,
            });
        }
        else {
            dispatch(performLogout());
            dispatch({
                type: _LOGIN_FAILED,
                message: responseJson[ApiKey._API_MESSAGE_KEY],
            });
            dispatch(showMessage(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
        }
        dispatch(setIdle());
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to login if it is already considered authenticated in cookie
/// </summary>
export const updateLoginUser = (userData) => {
    localStorage.setItem("userData", JSON.stringify(userData));
    return {
        type: _LOGIN_SUCCESS,
        userData: userData,
    }
}

/// <summary>
/// Author : -
/// expose logout action as function to be called from classes
/// </summary>
export const performLogout = () => {
    return async dispatch => {
        const response = await fetch(ApiUrl._API_LOGOUT, {
            method: ApiKey._API_POST,
            headers: {
                'Content-Type': ApiKey._API_FORM_URLENCODED,
                "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
            }
        });

        await response.json();

        dispatch({
            type: _LOGOUT
        });
    }
}

/// <summary>
/// Author : -
/// this function is to only update user status to logout
/// </summary>
export const resetLogin = () => {
    return async dispatch => {
        dispatch({
            type: _LOGOUT
        });
    }
}

/// <summary>
/// Author : -
/// </summary>
export const checkIsLoggedIn = (loginData) => {
    return async dispatch => {
        var requireToCheck = false;

        if (loginData.token) {
            let prevToken = localStorage.getItem("_t");
            if (prevToken !== loginData.token) {
                dispatch(performLogin(loginData.username, loginData.password, loginData.token));
            } else {
                requireToCheck = true;
            }
        } else {
            requireToCheck = true;
        }

        if(requireToCheck) {
            await fetch(ApiUrl._API_IS_LOGGED_IN,
                {
                    method: ApiKey._API_GET,
                    headers:
                    {
                        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY
                    }
                })
                .then((response) => response.json())
                .then((responseJson) => {
                    let data = responseJson[ApiKey._API_DATA_KEY];
                    if (responseJson[ApiKey._API_SUCCESS_KEY] && data.username !== "undefined" && data.token != null) {
                        dispatch(updateLoginUser(responseJson[ApiKey._API_DATA_KEY]));
                    }
                    else {
                        dispatch(resetLogin());
                    }
                });
        }
    }
}