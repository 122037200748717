import React, { useEffect, useState } from 'react';
import { Button, Jumbotron } from 'reactstrap';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { WebUrl, ApiUrl, ApiKey } from '../../util/Constant';
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import { useTranslation } from 'react-i18next';

const ConfirmationFooter = props => {
    const _history = useHistory();
    const _location = useLocation();
    const { confirmBtn, totalAmount, submitHandler } = props
    const _dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    var { t } = useTranslation();

    async function submissionHandler() {
        setIsLoading(true);

        if (typeof submitHandler === 'function') {
            submitHandler();
        }

        if (_location.pathname == WebUrl._BETTING_SUMMARY) {
            var cartObject = JSON.parse(sessionStorage.getItem("OrderCart"));
            if (cartObject != null) {
                let url = ApiUrl._API_MAKE_ORDER;
                await fetch(url,
                    {
                        method: ApiKey._API_POST,
                        headers:
                        {
                            'Accept': ApiKey._API_APPLICATION_JSON,
                            'Content-Type': ApiKey._API_APPLICATION_JSON
                        },
                        body: sessionStorage.getItem("OrderCart")
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                            sessionStorage.removeItem("OrderCart");
                            _history.push(WebUrl._BET_SUCCESSFUL)
                        }
                        else {
                            _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                        }
                        setIsLoading(false);
                    });
            }
        } else {
            setIsLoading(false);
        }

        return
    }

    return (
        <>
            <div className="form-footer">
                <div className="container set-footer-layout">
                    <Jumbotron className="footer-amount-wrapper">
                        <div className="line-height-1">
                            {totalAmount && (
                                <>
                                    <span>{t('TOTAL')}</span>
                                    <h2>{totalAmount}</h2>
                                </>
                            )}
                        </div>
                        <div className="m-auto m-r-0">
                            {confirmBtn && (
                                <Button disabled={isLoading} className="btn theme-btn-2" onClick={submissionHandler} type="button">
                                    {_location.pathname == WebUrl._BETTING_SUMMARY ? t('CONFIRM_BET') : t('SUBMIT') }
                                </Button>
                            )}
                        </div>
                    </Jumbotron>
                </div>
            </div>

        </>
    )
}
ConfirmationFooter.defaultProps = {
    confirmBtn: PropTypes.bool
}


export default ConfirmationFooter;