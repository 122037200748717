import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.jsx';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./ApplicationStore.js";
import './localization/i18n.js';

// css
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/site.scss';
import './scss/react.scss';
import 'swiper/swiper.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'font-awesome/css/font-awesome.min.css';

//js
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

// ========================================

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);